import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() isVisible: boolean = true;  // Show modal by default on first visit
  @Input() useLocalStorage: boolean = true; // Input to enable/disable local storage
  @Output() modalClose = new EventEmitter<void>();

  modalShownKey = 'modalShown';  // Key for local storage
  ngOnInit() {
    if (this.useLocalStorage) {
      const modalShown = localStorage.getItem(this.modalShownKey);
      this.isVisible = !modalShown; // Show modal if not shown before
    }
  }

  closeModal() {
    this.isVisible = false;
    this.modalClose.emit();
    if (this.useLocalStorage) {
      localStorage.setItem(this.modalShownKey, 'true');
    }
  }
}
