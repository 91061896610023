import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { BannerService } from '../services/banner/banner.service';

/**
 * Guard to protect routes based on user authentication and authorization.
 */
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const bannerService = inject(BannerService);
  // Retrieve the current user from the authentication token
  const user = authService.currentUserInfoValue;

  // If no user is logged in, redirect to login page
  if (!user) {
    router.navigate(['/login']);
    return false;
  }
  // Extract the required role and subscription plan from the route data
  const requiredRole = route.data['requiredRole'];
  const requiredSubscription = route.data['requiredSubscription'];

  // Check if the user has the required role and subscription
  if (authService.isAuthorized(user, requiredRole, requiredSubscription)) {
    return true; // Access granted
  } else {
    // Show an error banner and redirect to the 'Not Authorized' page
    bannerService.showBanner("Access Denied: Insufficient Permissions", "warning", true);
    router.navigate(['/not-authorized']);
    return false; // Access denied
  }
};
