<section class="section" id="login">
    <div class="container">
        <div class="section-box" data-aos="fade-up" data-aos-duration="1000">
            <div class="cizeex-plus-title">
                <h3 class="e-font section-title">
                    {{ "Header.Item5" | translate }}
                </h3>
            </div>
            <div class="login-container">
                <!-- Login header -->
                <div class="login-header">
                    <h2>Login to Cizeex+</h2>
                    <p>Access exclusive benefits and personalized insights</p>
                </div>
                <!-- Login form -->
                <form class="margin-left-6" class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div>
                        <input type="text" id="username" formControlName="username" placeholder="Enter Username">
                        <div class="margin-left-6" *ngIf="loginForm.get('username').hasError('required') && loginForm.get('username').touched">
                            Username is required
                        </div>
                        <input type="password" id="password" formControlName="password" placeholder="Enter Password">
                        <div class="margin-left-6" *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
                            Password is required
                        </div>
                        <div class="margin-left-6" *ngIf="error">
                            {{error}}
                        </div>
                        <button type="submit" [disabled]="!loginForm.valid">
                            <a class="btn btn-1">
                                <svg>
                                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                </svg>
                                Login
                            </a>
                        </button>
                        <app-loading></app-loading>
                        <p class="forgot-password"><a (click)="showForgotPassword = !showForgotPassword">Forgot password?</a></p>
                        <div class="forgot-password" *ngIf="showForgotPassword">
                            Please contact support.
                        </div>
                    </div>
                </form>
                <!-- Social login options -->
                <!-- <div>
                    <button type="submit" [disabled]="!loginForm.valid">
                        <a class="btn btn-1">
                            <svg>
                                <rect x="0" y="0" fill="none" width="100%" height="100%" />
                            </svg>
                            Login with Google
                        </a>
                    </button>
                    <button type="submit" [disabled]="!loginForm.valid">
                        <a class="btn btn-1">
                            <svg>
                                <rect x="0" y="0" fill="none" width="100%" height="100%" />
                            </svg>
                            Login with GitHub
                        </a>
                    </button>
                </div> -->
                <!-- Register link -->
                <p class="register-link">Don't have an account? <a  routerLink="/registration" [queryParams]="{ role: Roles.Cizeex }">Register now</a></p>
            </div>
        </div>
    </div>
</section>