import { AfterViewInit, Component } from '@angular/core';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { NewsComponent } from './news/news.component';
import { GreedFearIndexComponent } from './greed-fear-index/greed-fear-index.component';
import { DiscordHelpComponent } from '../general/discord-help/discord-help.component';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrl: './market.component.scss'
})
export class MarketComponent implements AfterViewInit {
  active = 0

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  contentComponent: any = NewsComponent;

  onTabSelected(index: number) {
    switch (index) {
      case 0:
        this.contentComponent = NewsComponent;
        break;
      case 1:
        this.contentComponent = EconomicCalendarComponent;
        break;
      case 2:
        this.contentComponent = GreedFearIndexComponent;
        break;
      case 3:
        this.contentComponent = DiscordHelpComponent;
        break;
      default:
        break;
    }
  }
}
