import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getJobs(
    keyword: string,
    positionTitle: string,
    location: string,
    page: number,
    pageSize: number
  ): Observable<{ jobs: any[]; totalResults: number }> {
    let params = new HttpParams()
      .set('keyword', keyword)
      .set('positionTitle', positionTitle)
      .set('location', location)
      .set('results_per_page', pageSize)
      .set('page', page.toString());

    return this.http.get<any>(`${this.baseUrl}jobs/gov`, { params }).pipe(
      map((response) => ({
        jobs: response.searchResult?.searchResultItems?.map(item => item.matchedObjectDescriptor) || [],
        totalResults: response.searchResult?.searchResultCountAll ?? 0, // Ensure totalResults is 0 if undefined
      })),
      catchError((error) => {
        console.error('Error fetching jobs:', error);
        return of({ jobs: [], totalResults: 0 }); // Return empty list with 0 results on error
      })
    );
  }
}
