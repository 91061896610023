import { Component } from '@angular/core';

@Component({
  selector: 'app-background-theme',
  templateUrl: './background-theme.component.html',
  styleUrl: './background-theme.component.scss'
})
export class BackgroundThemeComponent {
  currentTheme = 'dark';
  toggleTheme() {
    this.currentTheme = this.currentTheme === 'dark' ? 'light' : 'dark';
    document.body.classList.toggle('dark-mode');
  }
}
