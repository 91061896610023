import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from 'src/app/models/article.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    private apiUrl = environment.authApiUrl;

    constructor(private http: HttpClient) { }

    getArticles(userId?: number): Observable<Article[]> {
        let url = `${this.apiUrl}articles/`;
        if (userId) {
            url += `?userId=${userId}`;
        }
        return this.http.get<Article[]>(url);
    }

    getArticleById(articleId: number): Observable<Article> {
        return this.http.get<Article>(`${this.apiUrl}articles/${articleId}`);
    }

    createArticle(articleData: any): Observable<any> {
        return this.http.post(`${this.apiUrl}articles/`, articleData);
    }

    updateArticle(id: number, articleData: any): Observable<any> {
        return this.http.put(`${this.apiUrl}articles/${id}`, articleData);
    }

    autoSave(id: number, content: string) {
        // Implement auto-save functionality (e.g., save every few seconds)
    }

    likeArticle(id: number): Observable<any> {
        return this.http.post(`${this.apiUrl}articles/${id}/like`, {});
    }

    incrementViews(articleId: number): Observable<any> {
        return this.http.post(`${this.apiUrl}/articles/${articleId}/views`, {});
    }

    hideArticle(articleId: number): Observable<any> {
        return this.http.post(`${this.apiUrl}articles/${articleId}/hide`, {});
    }

    unhideArticle(articleId: number): Observable<any> {
        return this.http.post(`${this.apiUrl}articles/${articleId}/unhide`, {});
    }

    deleteArticle(articleId: number): Observable<any> {
        return this.http.post(`${this.apiUrl}articles/${articleId}/delete`, {});
    }
}
