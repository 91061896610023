import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavListComponent } from './nav-list/nav-list.component';
import { DropdownsComponent } from './dropdowns/dropdowns.component';
import { TabsComponent } from './tabs/tabs.component';
import { LoadingComponent } from './loading/loading.component';
import { GridComponent } from './grid/grid.component';
import { PascalCasePipe } from 'src/app/pipes/pascal-case.pipe';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { UserHeaderComponent } from './user-header/user-header.component';
import { RadarChartComponent } from './graphs/radar-chart/radar-chart.component';
import { PromptUiComponent } from './prompt-ui/prompt-ui.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { BannerComponent } from './banner/banner.component';
import { DiscordHelpComponent } from './discord-help/discord-help.component';
import { ModalComponent } from './modal/modal.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavListComponent,
    DropdownsComponent,
    TabsComponent,
    LoadingComponent,
    GridComponent,
    PascalCasePipe,
    TruncatePipe,
    UserHeaderComponent,
    RadarChartComponent,
    PromptUiComponent,
    MultiselectComponent,
    BannerComponent,
    DiscordHelpComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [HeaderComponent, FooterComponent,
    DropdownsComponent, TabsComponent,
    LoadingComponent, GridComponent,
    MultiselectComponent, RadarChartComponent,
    PromptUiComponent, BannerComponent, 
    DiscordHelpComponent, ModalComponent]
})
export class GeneralModule { }
