<div *ngIf="!auth.currentUserValue">
    <app-modal [isVisible]="isModalVisible" [useLocalStorage]="true" (modalClose)="closeModal()">
        <section class="more-projects">
            <div routerLink="/code-collaborate"
                (click)='analyticsService.sendAnalyticEvent("click_ad_text", "project", "click")'>
                <h2 class="attention-red-effect">Learn by doing, with expert guidance and real-world projects!</h2>
                <h5 class="attention-red-effect">Unlock Your Coding Potential with <span
                        class="underline">CodeCollaborate</span> -
                    <span style="color: aqua; font-size:x-large;" class="underline">Free to Get Started!</span>
                </h5>
            </div>
            <!-- class="col-12 col-md-6 col-lg-6 col-xl-4 project-col" -->
            <div class="row p-0">
                <div *ngFor='let project of "OtherProjects.Projects" | translate; let i = index'
                    (click)="redirect(project['demoLink'], $event)"
                    class="col-12 col-md-4 col-lg-4 col-xl-3 project-col">
                    <div class="more-project-box scroll-projects"
                        (click)='analyticsService.sendAnalyticEvent("click_ad_project_tile", "project", "click")'
                        routerLink="/registration" [queryParams]="{ role: Roles.Coder }">
                        <div class="row w-100 text-left m-0 p-0">
                            <div class="col-6 p-0">
                                <img src='assets/icons/folder.svg' width="40">
                            </div>
                            <div class="col-6 d-flex p-0 justify-content-end">
                                <a style='color: inherit' [href]="project['demoLink']" target="_blank"><i id="demoLink"
                                        class="ml-3 fas fa-external-link-alt"></i></a>
                                <a style='color: inherit' [href]="project['ghLink']" target="_blank"><i id="ghLink"
                                        class="ml-4 fab fa-github"></i></a>
                            </div>
                            <h5 class="other-project-title mt-4">{{project.Title}}</h5>
                            <p class="other-project-description">
                                {{project.Description}}
                            </p>
                        </div>
                        <div>
                            <ul class="more-projects-skills">
                                <li *ngFor='let technology of project.Technologies'>
                                    <span class="underline">{{technology}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <p>Pick a project to start learning and creating real-world solutions today!</p>
            <p><span class="underline"><a style="all: unset; cursor: pointer;" routerLink="/registration"
                        [queryParams]="{ role: Roles.Coder }"
                        (click)='analyticsService.sendAnalyticEvent("click_ad_signup", "project", "click")'>Sign Up Now
                        and start growing your skills today!</a></span>
            </p>
        </section>
    </app-modal>
</div>