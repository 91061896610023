import { Component } from '@angular/core';
import { GreedFearIndexComponent } from './greed-fear-index/greed-fear-index.component';
import { MoneyFlowTimelineComponent } from './money-flow-timeline/money-flow-timeline.component';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrl: './charts.component.scss'
})
export class ChartsComponent {
  active = 0

  contentComponent: any = MoneyFlowTimelineComponent;

  onTabSelected(index: number) {
    switch (index) {
      case 0:
        this.contentComponent = MoneyFlowTimelineComponent;
        break;
      case 1:
        this.contentComponent = GreedFearIndexComponent;
        break;
      default:
        break;
    }
  }
}
