<section class="section" id="canceled-plan">
    <div class="container">
        <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
            <div class="cizeex-plus-title">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">05.</span>
                    {{ "Header.Item6" | translate }}
                </h3>
            </div>
            <div class="generic-container">
                <!-- plan header -->
                <div class="generic-header">
                    <h2>Subscription Canceled</h2>
                    <p>Your subscription has been canceled. We hope to see you again soon!.</p>
                </div>
                <button routerLink="/registration" [queryParams]="{ role: Roles.Coder }">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Manage Subscription
                    </a>
                </button>
                <button routerLink="/home">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Go back to Cizeex
                    </a>
                </button>
            </div>
        </div>
    </div>
</section>