import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BannerService } from 'src/app/services/banner/banner.service';
import { GithubAuthService } from 'src/app/services/auth/github-auth.service';
import { LoadingService } from 'src/app/services/loading-service/loading.service';

@Component({
  selector: 'app-code-collaborate-dashboard',
  templateUrl: './code-collaborate-dashboard.component.html',
  styleUrl: './code-collaborate-dashboard.component.scss'
})
export class CodeCollaborateDashboardComponent implements OnInit {
  isGitHubLinked: boolean = false;
  isUserEnrolled: boolean = false;
  repos: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private githubAuthService: GithubAuthService,
    private loadingService: LoadingService,
    private bannerService: BannerService
  ) { }

  ngOnInit() {
    const code = this.route.snapshot.queryParamMap.get('code');
    const storedGitHubToken = localStorage.getItem('github_token');

    if (storedGitHubToken) {
      this.isGitHubLinked = true;
      this.checkUserEnrollment(storedGitHubToken);
    } else if (code) {
      this.exchangeCodeForToken(code);
    }
  }

  // Exchange the GitHub code for a token
  exchangeCodeForToken(code: string) {
    this.githubAuthService.getGitHubToken(code).subscribe(
      token => {
        localStorage.setItem('github_token', token.access_token);
        this.isGitHubLinked = true;
        this.checkUserEnrollment(token.access_token);
      },
      error => {
        this.bannerService.showBanner("Failed to link GitHub account.", "error", true);
      }
    );
  }

  // Check if the user is enrolled in the GitHub organization
  checkUserEnrollment(githubToken: string) {
    this.loadingService.showSection();
    this.githubAuthService.isUserEnrolledInOrg(githubToken).subscribe(
      isEnrolled => {
        this.isUserEnrolled = isEnrolled;
        this.loadingService.hideSection();

        if (isEnrolled) {
          this.loadUserRepos();
        } else {
          this.enrollInOrganization();
        }
      },
      error => {
        this.bannerService.showBanner("Failed to check membership status.", "error", true);
        this.loadingService.hideSection();
      }
    );
  }

  // Enroll user in the GitHub organization
  enrollInOrganization() {
    this.loadingService.showSection();
    this.githubAuthService.enrollInGitHubOrg().subscribe(
      response => {
        if (response.success) {
          this.isUserEnrolled = true;
          this.loadUserRepos();
          this.bannerService.showBanner("Successfully enrolled in the organization!", "success", true);
        } else {
          this.bannerService.showBanner("Failed to enroll. Please try again.", "warning", true);
        }
        this.loadingService.hideSection();
      },
      error => {
        this.bannerService.showBanner("Failed to enroll in the organization.", "error", true);
        this.loadingService.hideSection();
      }
    );
  }

  // Load the user's GitHub repositories
  loadUserRepos() {
    this.loadingService.showSection();
    this.githubAuthService.getUserRepos().subscribe(
      repos => {
        this.repos = repos;
        this.loadingService.hideSection();
      },
      error => {
        this.bannerService.showBanner("Failed to load repositories.", "error", true);
        this.loadingService.hideSection();
      }
    );
  }

  // Redirect user to GitHub to link their account
  linkGitHubAccount() {
    const clientId = environment.githubClientId;
    const redirectUri = encodeURIComponent(`${environment.url}code-collaborate-dashboard`);
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=repo,read:org`;
  }
}
