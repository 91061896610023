import { NgModule } from '@angular/core';
import { Routes, RouterModule, withDebugTracing } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { MarketComponent } from './components/market/market.component';
import { CodeCollaborateComponent } from './components/code-collaborate/code-collaborate.component';
import { TermsOfServiceComponent } from './components/home/terms-of-service/terms-of-service.component';
import { RegistrationComponent } from './components/user/registration/registration.component';
import { LoginComponent } from './components/user/login/login.component';
import { PaymentSuccessComponent } from './components/user/subscription-pages/payment-success/payment-success.component';
import { Roles } from './enums/roles.enum';
import { AuthGuard } from './guards/auth.guard';
import { PrivacyPolicyComponent } from './components/home/privacy-policy/privacy-policy.component';
import { CodeCollaborateDashboardComponent } from './components/code-collaborate/code-collaborate-dashboard/code-collaborate-dashboard.component';
import { ArticleListComponent } from './components/article/article-list/article-list.component';
import { ArticleEditorWrapperComponent } from './components/article/article-editor-wrapper/article-editor-wrapper.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'market', component: MarketComponent, data: { dontRedirect: true }},
  { path: 'code-collaborate', component: CodeCollaborateComponent, data: { dontRedirect: true }},
  { path: 'articles/new', component: ArticleEditorWrapperComponent },
  { path: 'articles', component: ArticleListComponent, data: { dontRedirect: true }},
  { path: 'articles/:id', component: ArticleEditorWrapperComponent, data: { dontRedirect: true }},
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./components/user/user.module').then(m => m.UserModule)
      }
    ]
  },
  { path: 'registration', component: RegistrationComponent ,data: { dontRedirect: true }},
  { path: 'login', component: LoginComponent ,data: { dontRedirect: true }},
  { path: 'payment-success', component: PaymentSuccessComponent ,data: { dontRedirect: true }},
  { path: 'terms-of-service', component: TermsOfServiceComponent,data: { dontRedirect: true, dontTranslate: true }},
  { path: 'privacy-policy', component: PrivacyPolicyComponent,data: { dontRedirect: true, dontTranslate: true }},
  { 
    path: 'code-collaborate-dashboard', 
    component: CodeCollaborateDashboardComponent, 
    canActivate: [AuthGuard], 
    data: { 
      dontRedirect: true, 
      requiredRole: [Roles.Coder, Roles.Collaborator]
    }
  },
  { path: ':language(en|es|iz)?', component: HomeComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 // {path: 'profile'        ,   component: ProfileComponent         , canActivate: [AuthGuard]},
 // {path: 'users'          ,   component: UsersComponent           , canActivate: [AuthGuard]},
 // {path: 'register-user'  ,   component: RegisterUserComponent    , canActivate: [AuthGuard]},
 // {path: ':language?/proyectos', component: ArchiveComponent },
