import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { parse, format, isValid, subMinutes, subHours } from 'date-fns';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnChanges {
  @Input() data: any[] = [];
  @Input() dateKey: string = 'date';
  @Input() datetimeKey: string = 'datetime';
  @Input() excludeHeaders: string[] = [];
  @Input() orderHeaders: string[] = [];
  @Input() aiReaction: string = '';
  @Input() aiReactionOff = false;
  @Input() enableResizeView = false;
  @Input() enablePagination: boolean = false; // Toggle pagination
  @Input() pageSize: number = 10; // Number of items per page
  @Input() totalPages: number = 1; // Passed from the parent component
  @Output() pageChanged = new EventEmitter<number>(); // Emit current page
  @Output() rowClicked = new EventEmitter<any>();
  isTableView = true; // Default to table view
  currentPage: number = 1;
  paginatedData: any[] = [];
  groupedEvents: any[] = [];
  headers: string[] = [];
  aiReactionInputValue: string;
  expandedEvent: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.groupDataByDate(this.data);
      this.extractHeaders(this.data);
    }
    if (changes.aiReaction) {
      // Handle changes to aiReaction if needed
      this.aiReactionInputValue = this.aiReaction;
    }
    if (changes.data || changes.pageSize) {
      this.updatePaginatedData();
    }
  }

  /**
   * Parses a relative time string into a Date object.
   *
   * @param {string} timeString - Time string to parse (e.g., "30 min", "2 hour", "2023-03-15T14:30:00")
   * @returns {Date} Parsed Date object
   */
  parseRelativeTime(timeString) {
    const now = new Date();

    // Handle minutes (e.g., "30 min")
    if (timeString.includes('min')) {
      const minutes = parseInt(timeString.split(' ')[0], 10);
      return subMinutes(now, minutes);
    }

    // Handle ISO 8601 format (e.g., "2023-03-15T14:30:00")
    else if (timeString.includes('T')) {
      // To show only time, use getHours(), getMinutes(), and getSeconds()
      return new Date(timeString);
    }

    // Handle hours (e.g., "2 hour")
    else if (timeString.includes('hour')) {
      const hours = parseInt(timeString.split(' ')[0], 10);
      return subHours(now, hours);
    }

    // Handle 12-hour time format (e.g., "02:30 PM")
    else if (timeString.includes('AM') || timeString.includes('PM')) {
      return parse(timeString, 'hh:mma', now);
    }

    // Handle month-day format (e.g., "Mar-15")
    else {
      return parse(timeString, 'MMM-dd', now);
    }
  }
  groupDataByDate(events) {
    const eventsByDate = {};

    events.forEach(event => {
      let dateTime;
      const dateStr = event[this.dateKey];

      // First, try to parse relative time formats
      dateTime = this.parseRelativeTime(dateStr);

      // If dateTime is still invalid, try other date formats
      if (!isValid(dateTime)) {
        const formats = ["EEE, dd MMM yyyy HH:mm:ss", 'yyyy-MM-dd', 'MMM-dd', 'hh:mma', 'dd/MM/yyyy'];
        for (let formatStr of formats) {
          dateTime = parse(dateStr, formatStr, new Date());
          if (isValid(dateTime)) break;
        }
      }

      // Handle invalid date
      if (!isValid(dateTime)) {
        console.error(`Invalid date format: ${dateStr}`);
        return;
      }

      // Create a date string without time
      const date = format(dateTime, 'yyyy-MM-dd');

      if (!eventsByDate[date]) {
        eventsByDate[date] = [];
      }
      eventsByDate[date].push(event);
    });

    this.groupedEvents = Object.keys(eventsByDate).map(date => ({
      date,
      events: eventsByDate[date].sort((a, b) => new Date(a[this.datetimeKey]).getTime() - new Date(b[this.datetimeKey]).getTime())
    }));
  }

  extractHeaders(data: any[]) {
    if (data.length > 0) {
      // Get all keys from the first data item
      const allHeaders = Object.keys(data[0]);

      // Filter headers based on exclusions
      const filteredHeaders = allHeaders.filter(header => !this.excludeHeaders.includes(header));

      // Reorder filtered headers based on objReorder
      if (this.orderHeaders?.length === 0) {
        this.headers = filteredHeaders;
      }
      else {
        this.headers = this.orderHeaders.filter(header => filteredHeaders.includes(header));
      }
    }
  }

  toggleExpand(event: any) {
    if (this.aiReactionOff) return;
    this.aiReactionInputValue = null;
    if (this.expandedEvent && this.expandedEvent !== event) {
      this.expandedEvent.isExpanded = false;  // Collapse the previously expanded event
    }
    event.isExpanded = !event.isExpanded;
    this.expandedEvent = event.isExpanded ? event : null;
  }

  toggleView() {
    this.isTableView = !this.isTableView;
  }

  onRowClick(event: any) {
    this.rowClicked.emit(event);
  }

  updatePaginatedData() {
    if (this.enablePagination) {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      this.paginatedData = this.data.slice(startIndex, startIndex + this.pageSize);
    } else {
      this.paginatedData = this.data;
    }
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages && page !== this.currentPage) {
      this.currentPage = page;
      this.updatePaginatedData();
      this.pageChanged.emit(this.currentPage);
    }
  }

  // Compute a limited array of pages with ellipsis.
  get visiblePages(): (number | string)[] {
    if (!this.totalPages || this.totalPages < 1) return [];

    const total = this.totalPages;
    const current = this.currentPage;
    const delta = 2; // how many pages to show around the current page

    let left = Math.max(1, current - delta);
    let right = Math.min(total, current + delta);
    const pages: (number | string)[] = [];

    if (left > 1) {
      pages.push(1);
      if (left > 2) {
        pages.push('...');
      }
    }

    for (let i = left; i <= right; i++) {
      pages.push(i);
    }

    if (right < total) {
      if (right < total - 1) {
        pages.push('...');
      }
      pages.push(total);
    }

    return pages;
  }

}
