import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, query, transition, stagger, animate } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UntypedFormControl } from '@angular/forms';
import { LanguageService } from 'src/app/services/language/language.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger("animateMenu", [
      transition(":enter", [
        query(".animate-item", [
          style({ opacity: 0, transform: "translateY(-40%)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" }))
          ])
        ])
      ])
    ])
  ]
})

export class HeaderComponent implements OnInit {

  responsiveMenuVisible = false;
  pageYPosition: number;
  isHovered = false;
  languageFormControl: UntypedFormControl = new UntypedFormControl();
  btnStyle = 'dropdown-default';
  cizeexServices = [
    { name: 'CodeCollaborate', route: '/code-collaborate' },
    { name: 'Market Insights', route: '/market' },
    { name: 'ArtIcles', route: '/articles' },
    { name: 'Jobs', route: '/jobs' },
    // { name: 'Rejected', route: '/stock-market-tools' }
  ];

  constructor(
    private router: Router,
    public analyticsService: AnalyticsService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.languageFormControl.valueChanges.subscribe(val => this.languageService.changeLanguage(val));
    this.languageFormControl.setValue(this.languageService.language);
  }

  openDiscordInvite(): void {
    window.open('https://discord.gg/kvFKcNjhnE', '_blank');
  }

  scroll(el) {
    if (document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/home']).then(() => document.getElementById(el).scrollIntoView({ behavior: 'smooth' }));
    }
    this.responsiveMenuVisible = false;
  }

  stylechangeevent(btnStyle: string) {
    this.btnStyle = btnStyle;
  }

  @HostListener('window:scroll', ['getScrollPosition($event)'])
  getScrollPosition(event) {
    this.pageYPosition = window.pageYOffset
  }

  changeLanguage(language: string) {
    const avoidTranslate = this.languageService.avoidTranslate();
    avoidTranslate ? false : this.languageFormControl.setValue(language);
  }
}
