import { Component, Input, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-discord-help',
  templateUrl: './discord-help.component.html',
  styleUrls: ['./discord-help.component.scss']
})
export class DiscordHelpComponent implements OnInit {
  @Input() sectionNumber: string = '';

  constructor(private metaService: MetaService) {};
  ngOnInit(): void {
    this.metaService.setMetaTags({
      title: "Join Discord",
      description: "Join our Discord community to connect with our team and fellow members who can assist you with any setup questions or guidance you need.",
      keywords: "Stock, News, Market, Crypto, Bitcoin, Tesla, Nvidia, DJT"
    });
  }
}
