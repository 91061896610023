import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { routes } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language: "es" | "en";

  constructor(
    public translateService: TranslateService,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  initLanguage() {
    this.translateService.addLangs(["en", "es", "iz"]);
    let language = navigator.language || (navigator as any).userLanguage;
    language = language.split("-").includes("es") ? "es" : language.split("-").includes("iz") ? "iz" : "en";
    this.translateService.setDefaultLang(language);
    // Change the URL without navigate
    if (!this.checkRoute()) this.location.go(language);
    this.language = language;

  }

  changeLanguage(language) {
    this.translateService.setDefaultLang(language)
    if (!this.checkRoute()) this.location.go(language);
    this.language = language
  }

  checkRoute(): boolean {
    const currentPath = this.location.path().split('?')[0]; // Remove query parameters

    for (const route of routes) {
      const routePath = `/${route.path}`; // Full route path from definition

      if (this.matchPath(routePath, currentPath)) {
        return route.data?.dontRedirect || false; // Safely access dontRedirect
      }
    }

    return false; // Default to false if no match is found
  }

  // Helper function to match paths with dynamic segments
  private matchPath(routePath: string, currentPath: string): boolean {
    const routeSegments = routePath.split('/'); // Split route into segments
    const pathSegments = currentPath.split('/'); // Split current path into segments

    if (routeSegments.length !== pathSegments.length) {
      return false; // If lengths don't match, paths can't match
    }

    return routeSegments.every((segment, index) => {
      return segment.startsWith(':') || segment === pathSegments[index];
      // Match dynamic segment (e.g., :id) or exact segment
    });
  }

  avoidTranslate(): boolean {
    const currentPath = this.location.path();
    for (const route of routes) {
      if (`/${route.path}` === currentPath.split('?')[0]) {
        return (route.data && route.data.dontTranslate);
      }
    }
    return false;
  }
}
