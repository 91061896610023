<section class="section" id="payment-success">
    <div class="container">
        <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
            <div class="cizeex-plus-title">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">05.</span>
                    {{ "Header.Item6" | translate }}
                </h3>
            </div>
            <div class="payment-success-container">
                <!-- plan header -->
                <div class="payment-success-header">
                    <h2>You have successfully subscribed to our service. Welcome aboard!</h2>
                    <p>We're excited to help you achieve your goals and explore new opportunities with us.</p>
                </div>
                <button (click)="getStarted()">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Get Started
                    </a>
                </button>
            </div>
        </div>
    </div>
</section>