import { AfterViewInit, Component } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-code-collaborate',
  templateUrl: './code-collaborate.component.html',
  styleUrl: './code-collaborate.component.scss'
})
export class CodeCollaborateComponent implements AfterViewInit {
  Roles = Roles;
  constructor(
    public analyticsService: AnalyticsService,
    private metaService: MetaService
  ) { }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    this.metaService.setMetaTags({
      title: 'CodeCollaborate',
      description: 'Learn By Doing! Interactive learning by contributing to real projects with hands-on experience that will set you apart in the industry.',
      keywords: "HTML, SCSS, Angular, Python, SQL, and AI prompting"
    });
  }
}
