import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { TickerService } from 'src/app/services/ticker/ticker.service';

@Component({
  selector: 'app-ticker-checker',
  templateUrl: './ticker-checker.component.html',
  styleUrls: ['./ticker-checker.component.scss'],
})
export class TickerCheckerComponent {
  tickerSymbol: string = '';
  result: any = null;
  private inputSubject = new Subject<string>();
  @Output() tickerSelected = new EventEmitter<any>();
  constructor(private tickerService: TickerService) { }

  ngOnInit() {
    this.inputSubject
      .pipe(
        debounceTime(500), // Wait 500ms after the last keystroke
        distinctUntilChanged(), // Ignore duplicate inputs
        switchMap((symbol) => {
          // Check if symbol is empty or null
          if (!symbol || symbol.trim() === '') {
            this.result = null; // Reset result if input is empty
            return []; // Return an empty array or Observable to avoid API call
          }
          return this.tickerService.checkTickerAvailability(symbol); // Call the service for non-empty input
        })
      )
      .subscribe(
        (res) => {
          if (res && res.available) {
            this.result = res; // Update result if ticker is available
          } else {
            this.result = { available: false }; // Reset if ticker is not available
          }
        },
        (error) => {
          console.error('Error fetching ticker:', error);
          this.result = null; // Reset result on error
        }
      );
  }

  onTickerInput(value: string) {
    this.inputSubject.next(value);
  }

  emitTicker(): void {
    if (this.result && this.result.available) {
      this.tickerSelected.emit(this.result); // Emit the ticker data
    }
  }
}
