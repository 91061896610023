import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/services/job/job.service';
import { LoadingService } from 'src/app/services/loading-service/loading.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('600ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class JobListComponent implements OnInit {
  jobs: any[] = [];
  hasData: boolean = false;
  keyword = '';
  positionTitle = '';
  location = '';
  page = 1;
  pageSize = 10;
  totalResults = 0;
  totalPages: number;

  constructor(private jobService: JobService, private loadingService: LoadingService,) { }

  ngOnInit(): void {
    this.fetchJobs();
  }

  onSearch() {
    this.page = 1;
    this.fetchJobs();
  }

  fetchJobs(): void {
    this.hasData = false;
    this.loadingService.showOverlay();

    this.jobService.getJobs(this.keyword, this.positionTitle, this.location, this.page, this.pageSize).subscribe(
      (response) => {
        this.loadingService.hideOverlay();
        if (response.totalResults === 0) {
          this.hasData = false;
          this.jobs = [];
          this.totalPages = 0; // Ensure no pagination when no results exist
        } else {
          this.hasData = true;
          this.jobs = response.jobs;
          this.totalResults = response.totalResults;
          this.totalPages = Math.ceil(this.totalResults / this.pageSize); // Ensure proper page count
        }
      },
      (error) => {
        console.error('Error fetching jobs:', error);
        this.loadingService.hideOverlay();
      }
    );
  }


  onRowClicked(event: any) {
    window.open(event.applyLink, '_blank');
  }

  onPageChanged(newPage: number): void {
    this.page = newPage;
    this.fetchJobs();
  }
}
