<div class="generic-full-container" data-aos="fade-up" data-aos-duration="1000">
    <div class="github-setup">
        <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">
            <!-- Conditionally display the section number -->
            <span class="code-font n-section-title" *ngIf="sectionNumber">{{ sectionNumber }}</span>
            {{ 'CodeCollaborate.Discord.Subject' | translate }}
        </h5>
        <div class="discord-image">
            <p data-aos="fade-up" data-aos-duration="1000" class="points-description"
                [innerHTML]="'CodeCollaborate.Discord.Description' | translate"></p>
            <br />
            <button>
                <a class="btn btn-1" href="https://discord.gg/kvFKcNjhnE" target="_blank">
                    <svg>
                        <rect x="0" y="0" fill="none" width="100%" height="100%" />
                    </svg>
                    <i class="fab fa-discord"></i> {{ 'CodeCollaborate.Discord.Button' | translate }}
                </a>
            </button>
        </div>
    </div>
</div>