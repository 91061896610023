import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import * as d3 from 'd3';
import { MoneyFlowTimeline } from '../../market/interface/money-flow-timeline';
@Component({
  selector: 'app-generic-timeline-chart',
  templateUrl: './generic-timeline-chart.component.html',
  styleUrls: ['./generic-timeline-chart.component.scss'],
})
export class GenericTimelineChartComponent implements OnInit, OnChanges {
  @Input() data: MoneyFlowTimeline[] = []; // Data passed from the parent
  @Input() categories: string[] = []; // Unique categories
  @Input() chartId = 'timeline-chart'; // ID to differentiate multiple instances
  @Output() assetRemoved = new EventEmitter<string>();

  private svg: any;
  private margin = { top: 30, right: 35, bottom: 30, left: 35 };
  private width = 800;
  private height = 450;
  private activeCategories = new Set<string>(); // Tracks active categories
  private activeAssets = new Set<string>(); // Tracks active categories  

  ngOnInit(): void {
    if (this.data.length && this.categories.length) {
      // Preserve user-selected categories instead of resetting them
      if (this.activeCategories.size === 0) {
        this.activeCategories = new Set(this.categories); // Enable all categories only on the first load
      }

      this.activeAssets = new Set(this.data.map(d => d.asset));

      this.createSvg();
      this.addLegend();
      this.updateChart();

      // Add a resize listener
      window.addEventListener('resize', () => this.onResize());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] || changes['categories']) {
      d3.select(`#${this.chartId}`).select('svg').remove(); // Clear previous chart
      d3.select(`#legend-${this.chartId}`).html(''); // Clear previous legend
      this.ngOnInit();
    }
  }

  private createSvg(): void {
    // Get the parent container's width
    const container = document.getElementById(this.chartId);
    if (!container) return;

    const containerWidth = container.offsetWidth - 30;
    const maxAllowedWidth = 1300; // Set your maximum width for the chart
    this.width = Math.min(containerWidth - this.margin.left - this.margin.right, maxAllowedWidth);

    // Add a scrollable wrapper
    d3.select(`#${this.chartId}`).style('overflow-x', 'auto').style('white-space', 'nowrap');

    // Create SVG
    this.svg = d3
      .select(`#${this.chartId}`)
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
  }

  private updateChart(): void {

    // Clear previous elements
    this.svg.selectAll('*').remove();

    // Filter data based on active categories and assets
    const filteredData = this.data.filter(
      (d) => this.activeCategories.has(d.category) && this.activeAssets.has(d.asset)
    );

    if (!filteredData.length) {
      return; // No data to display
    }

    // Parse dates
    const parseDate = d3.timeParse('%Y-%m-%d');
    filteredData.forEach((d) => (d.formattedDate = parseDate(d.date)));

    // Define scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(filteredData, (d: any) => d.formattedDate) as [Date, Date])
      .range([0, this.width]);

    const y = d3
      .scaleLinear()
      .domain([
        d3.min(filteredData, (d: any) => d.trend) || 0,
        d3.max(filteredData, (d: any) => d.trend) || 0,
      ])
      .range([this.height, 0]);

    // Add X-axis
    this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.height})`)
      .style('font-size', '10px')
      .style('font-family', 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace') // Adjust font family
      .call(d3.axisBottom(x));

    // Add Y-axis
    this.svg.append('g')
      .style('font-size', '10px')
      .style('font-family', 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace') // Adjust font family
      .call(d3.axisLeft(y));

    // Define line generator
    const line = d3
      .line()
      .x((d: any) => x(d.formattedDate))
      .y((d: any) => y(d.trend));

    // Define color scales
    const assetColor = d3.scaleOrdinal(d3.schemeTableau10);

    // Add lines for each active category and its assets
    const groupedData = d3.group(filteredData, (d: any) => d.category);
    groupedData.forEach((dataPoints, category) => {
      if (!this.activeCategories.has(category)) return; // Skip inactive categories

      const groupedAssets = d3.group(dataPoints, (d: any) => d.asset);

      groupedAssets.forEach((assetData, asset) => {
        const color = assetColor(asset);
        this.updateAssetColor(asset, color);
        const path = this.svg
          .append('path')
          .datum(assetData)
          .attr('class', `line-${this.safeId(asset)}`) // Unique class for the line
          .attr('fill', 'none')
          .attr('stroke', color) // Unique color for assets
          .attr('stroke-width', 2)
          .attr('d', line);

        // Add mouseover and mouseout events for highlighting
        const highlightLine = () => {
          d3.select(path.node())
            .transition()
            .duration(300)
            .attr('stroke-width', 4)
            .attr('filter', 'url(#glow)');
        };

        const resetLine = () => {
          d3.select(path.node())
            .transition()
            .duration(200)
            .attr('stroke-width', 2)
            .attr('filter', null);
        };

        path
          .on('mouseover', highlightLine)
          .on('mousemove', highlightLine)
          .on('mouseout', resetLine);

      });

      // Define the glow filter
      const defs = this.svg.append('defs');

      const glowFilter = defs
        .append('filter')
        .attr('id', 'glow')
        .attr('x', '-50%')
        .attr('y', '-50%')
        .attr('width', '200%')
        .attr('height', '200%');

      glowFilter
        .append('feGaussianBlur')
        .attr('stdDeviation', 2) // Adjust for desired glow intensity
        .attr('result', 'coloredBlur');

      glowFilter
        .append('feMerge')
        .selectAll('feMergeNode')
        .data(['coloredBlur', 'SourceGraphic'])
        .enter()
        .append('feMergeNode')
        .attr('in', (d) => d);

    });

    // Add tooltips
    const tooltip = d3
      .select(`#${this.chartId}`)
      .append('div')
      .style('position', 'absolute')
      .style('text-align', 'center')
      .style('padding', '6px')
      .style('font', '12px SF Mono')
      .style('background', '#eaf3fe')
      .style('border', '0px')
      .style('border-radius', '8px')
      .style('pointer-events', 'none')
      .style('opacity', 0.5)

    const showTooltip = (event: any, d: any) => {
      // Get the chart's bounding rectangle
      const chartRect = document.getElementById(this.chartId)?.getBoundingClientRect();
      if (!chartRect) return;

      const offsetX = chartRect.left;
      const offsetY = chartRect.top;

      tooltip
        .html(
          `<strong>Asset:</strong> ${d.asset}<br>
             <strong>Category:</strong> ${d.category}<br>
             <strong>Date:</strong> ${d3.timeFormat('%Y-%m-%d')(d.formattedDate)}<br>
             <strong>Trend:</strong> ${d.trend}%`
        )
        .transition()
        .duration(200)
        .style('opacity', 1)
        .style('left', `${event.clientX - offsetX + 30}px`)
        .style('top', `${event.clientY - offsetY + 270}px`);
    };


    const hideTooltip = () => {
      tooltip
        .transition()
        .duration(500)
        .style('opacity', 0);
    };

    // Bind filtered data to dots
    // Bind filtered data to dots
    this.svg
      .selectAll('.dot')
      .data(filteredData, (d: any) => `${d.category}-${d.asset}-${d.formattedDate}`) // Use unique keys for binding
      .join(
        (enter) =>
          enter
            .append('circle')
            .attr('class', 'dot')
            .attr('r', 5)
            .attr('fill', (d: any) => assetColor(d.asset)) // Color per asset
            .attr('cx', (d: any) => x(d.formattedDate))
            .attr('cy', (d: any) => y(d.trend))
            .on('mouseover', (event, d) => {
              showTooltip(event, d);
              const lineClass = `.line-${this.safeId(d.asset)}`;
              this.svg.select(lineClass)
                .transition()
                .duration(300)
                .attr('stroke-width', 4)
                .attr('filter', 'url(#glow)');
              // Dim all other lines
              this.svg.selectAll('path')
                .filter(function () {
                  return !d3.select(this).classed(lineClass.substring(1)); // Select non-hovered lines
                })
                .transition()
                .duration(300)
                .style('opacity', 0.3);
            })
            .on('mousemove', showTooltip)
            .on('mouseout', (event, d) => {
              hideTooltip();
              const lineClass = `.line-${this.safeId(d.asset)}`;
              this.svg.select(lineClass)
                .transition()
                .duration(200)
                .attr('stroke-width', 2)
                .attr('filter', null);
              // Reset opacity for all lines
              this.svg.selectAll('path')
                .transition()
                .duration(200)
                .style('opacity', 1);
            }),
        (update) =>
          update
            .attr('cx', (d: any) => x(d.formattedDate))
            .attr('cy', (d: any) => y(d.trend)),
        (exit) =>
          exit.remove()
      );
  }

  private addLegend(): void {
    const legend = d3.select(`#legend-${this.chartId}`);
    legend.html(''); // Clear any existing legend

    // Create a container for all categories to align them horizontally
    const legendContainer = legend.append('div')
      .attr('class', 'legend-container')
      .style('display', 'flex')
      .style('flex-wrap', 'wrap')
      .style('gap', '20px'); // Space between categories

    this.categories.forEach((category) => {
      const categoryData = this.data.filter((d) => d.category === category);
      const uniqueAssets = Array.from(new Set(categoryData.map((d) => d.asset))); // Unique assets per category

      // Category-level legend item
      const categoryContainer = legendContainer
        .append('div')
        .attr('class', 'legend-category')
        .style('flex', '1 0 200px') // Flexbox style to make categories responsive
        .style('min-width', '200px') // Minimum width for category container
        .style('margin-bottom', '10px')
        .style('padding', '10px')
        .style('border-radius', '8px')
        .style('overflow-x', 'auto')
        .style('white-space', 'nowrap')
        .style('background-color', '#eaf3fe');

      const categoryItem = categoryContainer
        .append('div')
        .attr('class', 'legend-item')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('margin-bottom', '5px');

      // Add expand/collapse button
      const toggleButton = categoryItem
        .append('span')
        .style('cursor', 'pointer')
        .style('margin-right', '10px')
        .style('margin-bottom', '3px')
        .style('font-weight', 'bold')
        .style('font-size', '16px')
        .text('▶') // Default collapsed state
        .on('click', () => {
          const isExpanded = assetContainer.style('display') === 'block';
          assetContainer.style('display', isExpanded ? 'none' : 'block');
          toggleButton.text(isExpanded ? '▶' : '▼'); // Update toggle icon
        });

      // Checkbox for the category
      categoryItem
        .append('input')
        .attr('type', 'checkbox')
        .attr('id', `checkbox-${this.safeId(category)}`)
        .attr('class', 'custom-checkbox')
        .property('checked', this.activeCategories.has(category)) // Ensure dynamic update
        .on('change', (event: any) => {
          if (event.target.checked) {
            this.activeCategories.add(category);
            // Check all assets in this category and add them to activeAssets
            uniqueAssets.forEach((asset) => {
              this.activeAssets.add(asset);
              d3.select(`#checkbox-${this.safeId(asset)}`).property('checked', true);
            });
          } else {
            this.activeCategories.delete(category);
            // Uncheck all assets in this category and remove them from activeAssets
            uniqueAssets.forEach((asset) => {
              this.activeAssets.delete(asset);
              d3.select(`#checkbox-${this.safeId(asset)}`).property('checked', false);
            });
          }
          this.updateChart(); // Update chart when checkbox state changes
        });

      // Label for the category
      categoryItem
        .append('label')
        .attr('for', `checkbox-${this.safeId(category)}`)
        .style('margin-left', '10px')
        .style('font-family', 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace')
        .style('font-size', '16px')
        .text(category);

      // Asset-level legend items (nested under the category)
      const assetContainer = categoryContainer
        .append('div')
        .attr('class', 'legend-assets')
        .style('display', 'none') // Start collapsed
        .style('margin-top', '10px');

      uniqueAssets.forEach((asset) => {
        const assetItem = assetContainer
          .append('div')
          .attr('class', 'legend-item')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('margin-bottom', '8px')
          .style('padding', '5px')

        // Checkbox for the asset
        assetItem
          .append('input')
          .attr('class', 'custom-checkbox')
          .attr('type', 'checkbox')
          .attr('id', `checkbox-${this.safeId(asset)}`)
          .attr('checked', this.activeAssets.has(asset)) // Reflect current state
          .on('change', (event: any) => {
            if (event.target.checked) {
              this.activeAssets.add(asset);
            } else {
              this.activeAssets.delete(asset);
            }
            this.updateChart(); // Update chart when checkbox state changes
          });

        // Add label for the asset
        assetItem
          .append('label')
          .attr('for', `checkbox-${this.safeId(asset)}`)
          .style('margin-left', '10px')
          .style('font-family', 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace')
          .style('font-size', '12px')
          .text(asset);

        // Add "X" icon to remove the asset
        assetItem
          .append('span')
          .attr('class', 'remove-icon')
          .style('cursor', 'pointer')
          .style('margin-left', '10px')
          .style('font-size', '18px')
          .style('color', 'red')
          .text('✖') // "X" character
          .on('click', () => this.removeAsset(asset)); // Call function to remove asset
      });
    });
  }

  private removeAsset(asset: string): void {
    // Remove the asset from activeAssets
    this.activeAssets.delete(asset);

    // Uncheck the corresponding checkbox
    d3.select(`#checkbox-${this.safeId(asset)}`).property('checked', false);

    // Remove the asset's legend item visually
    d3.select(`#checkbox-${this.safeId(asset)}`)
      .node()
      ?.parentElement?.remove();

    // Emit the removed asset to the parent component
    this.assetRemoved.emit(this.findTickerByAsset(asset));

    // Trigger chart update
    this.updateChart();
  }

  private findTickerByAsset(asset: string): string | undefined {
    const found = this.data.find(item => item.asset === asset);
    return found ? found.ticker : undefined;
  }

  private safeId(name: string): string {
    return name.replace(/[^\w-]/g, '-');
  }

  private onResize(): void {
    d3.select(`#${this.chartId}`).select('svg').remove(); // Clear previous SVG
    this.createSvg();
    this.updateChart();
  }

  private updateAssetColor(asset, assetColor) {
    const assetLabel = d3.select(`label[for="checkbox-${this.safeId(asset)}"]`);
    if (!assetLabel.empty()) {
      assetLabel.style('border-bottom', '2px solid');
      assetLabel.style('border-color', assetColor);// Update the label color
    }
  }
}