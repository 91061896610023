import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketComponent } from './market.component';
import { NewsComponent } from './news/news.component';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { ChartsComponent } from './charts/charts.component';


const routes: Routes = [
  {
    path: '',
    component: MarketComponent,
    children: [
      { path: 'news', component: NewsComponent, data: { dontRedirect: true } },
      { path: 'economic-calendar', component: EconomicCalendarComponent, data: { dontRedirect: true } },
      { path: 'charts', component: ChartsComponent, data: { dontRedirect: true } },
      { path: 'join-discord', component: ChartsComponent, data: { dontRedirect: true } },
      { path: 'securities-disclaimer', component: ChartsComponent, data: { dontRedirect: true } },
      { path: '', redirectTo: 'news', pathMatch: 'full' } // Default child route
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketRoutingModule { }
