<div class="cizeex-grid">
  <div *ngIf="enableResizeView" class="icon-order">
    <button class="main-btn main-btn-icon" (click)="toggleView()" title="Change Mobile View">
      <i [ngClass]="isTableView === true ? 'fas fa-mobile-alt' : 'fas fa-desktop'"></i>
    </button>
  </div>

  <div class="table-responsive" *ngIf="isTableView && groupedEvents" data-aos="fade-zoom-in"
    data-aos-easing="ease-in-back">
    <table class="table table-hover">
      <ng-container *ngFor="let dateGroup of groupedEvents">
        <thead class="table-header">
          <tr style="white-space: nowrap">
            <th colspan="{{ headers.length + 1 }}" style="text-align: left;">{{ dateGroup.date }}</th>
            <ng-container *ngFor="let header of headers">
              <th>{{ header | pascalCase }}</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let event of dateGroup.events">
            <tr (click)="onRowClick(event); toggleExpand(event)" [class.expanded]="event.isExpanded">
              <td>
                <span *ngIf="aiReactionOff" style="padding-right: 5px;" class="access-record"></span>
                <span *ngIf="!aiReactionOff" style="padding-right: 5px;"
                  [class]="event.isExpanded ? 'arrow-up' : 'arrow-down'"></span>
              </td>
              <ng-container *ngFor="let header of headers">
                <td style="height: 30px;" [title]="event[header]">
                  <ng-container>
                    <!-- Time display with conditional class -->
                    <span *ngIf="header.toLowerCase().includes('time')"
                      [ngClass]="{'calendar-date-time': event[header] !== null}">
                      {{ event[header] }}
                    </span>

                    <!-- Link display with conditional truncation -->
                    <span *ngIf="header.toLowerCase().includes('link')">
                      <a [href]="event[header]" target="_blank">
                        {{ event[header] | truncate: headers.length > 3 ? 30 : 60 }}
                      </a>
                    </span>

                    <!-- Default text display -->
                    <span *ngIf="!header.toLowerCase().includes('time') && !header.toLowerCase().includes('link')">
                      {{ event[header] | truncate }}
                    </span>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
            <tr class="expanded-tr" *ngIf="event.isExpanded">
              <td [attr.colspan]="headers.length + 1">
                <div class="reaction-ai">
                  <app-loading></app-loading>
                  <app-prompt-ui [aiReaction]="aiReactionInputValue"></app-prompt-ui>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </ng-container>
    </table>
  </div>

  <div *ngIf="!isTableView && groupedEvents" data-aos="fade-zoom-in" data-aos-easing="ease-in-back">
    <ng-container *ngFor="let dateGroup of groupedEvents">
      <div class="date-group-title">{{ dateGroup.date }}</div>
      <div [class]="event.isExpanded ? 'event-card event-card-active' : 'event-card'"
        *ngFor="let event of dateGroup.events">
        <div class="event-summary row" (click)="onRowClick(event); toggleExpand(event)">
          <!-- Loop through headers for each event -->
          <ng-container *ngFor="let header of headers">
            <ng-container>
              <!-- Time display with conditional class -->
              <span class="event-item calendar-date-time" *ngIf="header.toLowerCase().includes('time')">
                {{ event[header] }}
              </span>

              <!-- Link display with conditional truncation -->
              <span class="event-item" *ngIf="header.toLowerCase().includes('link')">
                <a style="color: inherit" [href]="event[header]" target="_blank">
                  <i id="demoLink" class="ml-1 fas fa-external-link-alt"></i>&nbsp;&nbsp;<span>Link</span>
                </a>
              </span>

              <!-- Default text display -->
              <span class="event-item"
                *ngIf="!header.toLowerCase().includes('time') && !header.toLowerCase().includes('link') && event[header]">
                <span class="underline">{{ header | pascalCase }}:</span> {{ event[header] }}
              </span>
            </ng-container>
          </ng-container>
          <!-- Arrow to indicate expansion -->
          <span *ngIf="!aiReactionOff" class="arrow-icon" [class]="event.isExpanded ? 'arrow-up' : 'arrow-down'"></span>
        </div>
        <!-- AI Reaction dropdown, visible on expansion -->
        <div *ngIf="event.isExpanded">
          <app-loading></app-loading>
          <app-prompt-ui [aiReaction]="aiReactionInputValue"></app-prompt-ui>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- Pagination Controls -->
  <div *ngIf="enablePagination" class="pagination-container">
    <nav>
      <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <button class="page-link" (click)="changePage(currentPage - 1)">Previous</button>
        </li>

        <li *ngFor="let page of visiblePages" class="page-item" [class.active]="page === currentPage">
          <button class="page-link" *ngIf="page !== '...'" (click)="changePage(page)">
            {{ page }}
          </button>
          <span class="page-link" *ngIf="page === '...'">{{ page }}</span>
        </li>

        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <button class="page-link" (click)="changePage(currentPage + 1)">Next</button>
        </li>
      </ul>
    </nav>
  </div>
  <div *ngIf="groupedEvents?.length === 0" style="min-height: 500px;">
    <p class="no-grid-data">No data found</p>
  </div>
</div>