import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { NewsService } from 'src/app/services/news-service/news.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';
import { NewsEvent } from '../../interface/news-event.model';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-market-news',
  templateUrl: './market-news.component.html',
  styleUrl: './market-news.component.scss',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('600ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MarketNewsComponent {
  events: NewsEvent[] = [];
  hasData: boolean = false;
  aiReaction: string | Promise<string> = '';
  constructor(
    private marketReactionService: MarketReactionService,
    private loadingService: LoadingService,
    private metaService: MetaService,
    private newsService: NewsService
  ) { };

  ngOnInit(): void {
    this.fetchNew();
    this.metaService.setMetaTags({
      title: "Market News",
      description: "Market News: Updates on global economic trends, financial indicators, and geopolitical events affecting markets.",
      keywords: "Stock, News, Market, Crypto, Bitcoin, Tesla, Nvidia, DJT"
    });
  }
  fetchNew() {
    this.hasData = false;
    this.loadingService.show();
    this.newsService.getMarketNews()
      .subscribe(
        data => {
          this.hasData = true;
          this.events = data;
          this.loadingService.hide();
        },
        error => {
          console.error('Error fetching news data:', error);
          this.loadingService.hide();
        }
      );
  }
  onRowClicked(event: NewsEvent) {
    this.loadingService.showSection();
    this.marketReactionService.getNewsReaction(event.title, event.link, event.time, 'Not Available', 'Not Available').subscribe(
      response => {
        const formattedResponse = this.marketReactionService.convertAiToHtml(response.response.toString());
        this.aiReaction = formattedResponse;
        this.loadingService.hideSection();
      },
      error => {
        console.error('Error fetching market reaction:', error);
        this.loadingService.hideSection();
      }
    );
  }
}
