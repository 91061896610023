<section class="section" id="login">
  <div class="container">
    <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
      <div class="cizeex-plus-title">
        <h3 class="e-font section-title">
          {{ "Header.Item6" | translate }}
        </h3>
      </div>
      <!-- Register container -->
      <div class="register-container">
        <div>
          <!-- Register header -->
          <div class="register-header">
            <h2>Join Cizeex</h2>
            <p>Unlock exclusive benefits and personalized insights</p>
          </div>
          <!-- Register form -->
          <form class="register-form" [formGroup]="userDetailsForm" (ngSubmit)="onSubmit()">
            <input type="text" id="username" formControlName="username" placeholder="Enter Username">
            <div
              *ngIf="userDetailsForm.get('username').invalid && (userDetailsForm.get('username').dirty || userDetailsForm.get('username').touched)">
              <div class="margin-left-6" *ngIf="userDetailsForm.get('username').errors.required">
                Username is required
              </div>
            </div>
            <input type="email" id="email" formControlName="email" placeholder="Enter Email">
            <div
              *ngIf="userDetailsForm.get('email').invalid && (userDetailsForm.get('email').dirty || userDetailsForm.get('email').touched)">
              <div class="margin-left-6" *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                <div *ngIf="email?.errors?.required">Email is required.</div>
                <div *ngIf="email?.errors?.email">Email must be a valid email address.</div>
                <div *ngIf="email?.errors?.invalidEmailDomain">Email must contain a valid domain with a period.</div>
              </div>
              <div class="margin-left-6" *ngIf="userDetailsForm.get('email').errors.email">
                Invalid email address
              </div>
            </div>
            <input type="password" id="password" formControlName="password" placeholder="Enter Password">
            <div
              *ngIf="userDetailsForm.get('password').invalid && (userDetailsForm.get('password').dirty || userDetailsForm.get('password').touched)">
              <div class="margin-left-6" *ngIf="userDetailsForm.get('password').errors.required">
                Password is required
              </div>
              <div class="margin-left-6" *ngIf="userDetailsForm.get('password').errors.minlength">
                Password must be at least 8 characters
              </div>
            </div>
            <input type="password" id="confirmPassword" formControlName="confirmPassword"
              placeholder="Re-Enter Password">
            <div
              *ngIf="userDetailsForm.get('confirmPassword').invalid && (userDetailsForm.get('confirmPassword').dirty || userDetailsForm.get('confirmPassword').touched)">
              <div class="margin-left-6" *ngIf="userDetailsForm.get('confirmPassword').errors.required">
                Confirm Password is required
              </div>
              <div class="margin-left-6" *ngIf="userDetailsForm.get('confirmPassword').errors.minlength">
                Confirm Password must be at least 8 characters
              </div>
            </div>
            <div class="tos">
              <label for="terms" for="terms">I agree to the <a routerLink="/terms-of-service">Terms of
                  Service</a>:</label>
              <input class="custom-checkbox margin-left-6" type="checkbox" id="terms" name="terms"
                formControlName="agreedToTerms">
              <div
                *ngIf="userDetailsForm.get('agreedToTerms').invalid && (userDetailsForm.get('agreedToTerms').dirty || userDetailsForm.get('agreedToTerms').touched)">
                <div class="margin-left-6" *ngIf="userDetailsForm.get('agreedToTerms').errors.required">
                  You must agree to the terms
                </div>
              </div>
            </div>
            <div class="margin-left-6" *ngIf="error">
              {{error}}
            </div>
            <button type="submit" [disabled]="!userDetailsForm.valid">
              <a class="btn btn-1">
                <svg>
                  <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
                {{ isCodeCollaborate ? 'Next' : 'Register'}}
                <!-- Register -->
              </a>
            </button>
            <app-loading></app-loading>
          </form>
          <!-- Social register options -->
          <!-- <div>
              <button type="submit" [disabled]="!userDetailsForm.valid">
                <a class="btn btn-1">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Register with Google
                </a>
              </button>
              <button type="submit" [disabled]="!userDetailsForm.valid">
                <a class="btn btn-1">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Register with GitHub
                </a>
              </button>
            </div> -->
          <!-- Login link -->
          <p class="login-link">Already have an account? <a routerLink="/login">Login now</a></p>
        </div>
      </div>
    </div>
  </div>
</section>