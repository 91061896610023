import { Component } from '@angular/core';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
})
export class PaymentSuccessComponent {
  planName: string;
  Roles = Roles;
  private _auth: AuthService;

  private operationId: string | null = null;
  RoleName: any;
  constructor(
    private stripeService: StripeService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    this._auth = authService;
  }

  get auth(): AuthService {
    return this._auth;
  }

  async ngOnInit() {
    const sessionId = this.route.snapshot.queryParamMap.get('session_id');
    const roleId = this.route.snapshot.queryParamMap.get('role_id');
    if (sessionId) {
      if (this.operationId) {
        // Prevent multiple operations with the same ID
        return;
      }
      this.loadingService.showSection();
      this.operationId = sessionId; // Set operation ID to prevent duplicates
      this.onPaymentSuccess(sessionId, roleId);
    } else {
      console.log("No session ID provided. Please try again.");
    }
  }

  onPaymentSuccess(sessionId: string, roleId: string) {
    this.stripeService.handlePaymentSuccess(sessionId, roleId)
      .then(() => {
        if (this._auth.currentUserInfoValue) {
          this.planName = Plans[this._auth.currentUserInfoValue.plan];
          this.RoleName = Roles[this._auth.currentUserInfoValue.role];
        }

        // Optionally handle additional logic after payment success
        console.log('Payment processed successfully.');
      })
      .catch(error => {
        // Handle any errors that were caught by the service
        console.error('Error handling payment success:', error);
      });
  }

  getStarted() {
    this.RoleName !== Roles[Roles.Cizeex]
      ? this.router.navigate(['/code-collaborate-dashboard'], { queryParams: { role: Roles[this.RoleName] } })
      : this.router.navigate(['/home']);
  }
}