<section class="section" id="jobs" title="Jobs">
  <div class="container">
    <div class="section-box">
      <div class="cizeex-plus-title" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="e-font section-title" alt="Jobs">
          <span class="code-font n-section-title">02.</span>
          {{ "Job.Title" | translate }}
        </h3>
      </div>
      <p class="points-description" [innerHTML]="'Job.Description' | translate">
      </p>
      <div class="search-filters">
        <input type="text" placeholder="Search by keyword..." [(ngModel)]="keyword" (keyup.enter)="onSearch()" />
        <input type="text" placeholder="Search by Position Title..." [(ngModel)]="positionTitle"
          (keyup.enter)="onSearch()" />
        <input type="text" placeholder="Location..." [(ngModel)]="location" (keyup.enter)="onSearch()" />
        <button class="main-btn" (click)="onSearch()">Search</button>
      </div>

      <div @fade>
        <app-loading *ngIf="!hasData"></app-loading>
        <app-grid *ngIf="jobs" [data]="jobs" [dateKey]="'postedDate'" [datetimeKey]="'postedDate'"
          [aiReactionOff]="true"
          [orderHeaders]="['positionTitle', 'location', 'salary', 'jobCategories', 'summary', 'applyLink', 'remote']"
          [enableResizeView]="true" (rowClicked)="onRowClicked($event)" [enablePagination]="true" [pageSize]="10"
          [totalPages]="totalPages" (pageChanged)="onPageChanged($event)">
        </app-grid>
      </div>
    </div>
  </div>
</section>