import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';
import { NewsService } from 'src/app/services/news-service/news.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { NewsEvent } from '../../interface/news-event.model';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-etf-news',
  templateUrl: './etf-news.component.html',
  styleUrl: './etf-news.component.scss',
  animations: [
    trigger('fade', [
      transition(':enter', [ // void => *
        style({ opacity: 0.5 }),
        animate('600ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class EtfNewsComponent {
  events: NewsEvent[] = [];
  hasData: boolean = false;
  aiReaction: string | Promise<string>;
  constructor(
    private marketReactionService: MarketReactionService,
    @Inject(DOCUMENT) private document: Document,
    private loadingService: LoadingService,
    private newsService: NewsService,
    private metaService: MetaService,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.fetchNew();
    this.metaService.setMetaTags({
      title: "ETF News",
      description: "ETF News: News about individual ETFs performance, market-moving etfs.",
      keywords: "ETF, News, Market, Crypto, Bitcoin, Tesla, Nvidia"
    });
  }
  fetchNew() {
    this.hasData = false;
    this.loadingService.show();
    this.newsService.getEtfNews()
      .subscribe(
        data => {
          this.hasData = true;
          this.events = data;
          this.loadingService.hide();
          this.generateFinanceSchemaMarkup(this.events);
        },
        error => {
          console.error('Error fetching news data:', error);
          this.loadingService.hide();
        }
      );
  }
  onRowClicked(event: NewsEvent) {
    this.loadingService.showSection();
    this.marketReactionService.getNewsReaction(event.title, event.link, event.time, event.stock, event.resource).subscribe(
      response => {
        const formattedResponse = this.marketReactionService.convertAiToHtml(response.response.toString());
        this.aiReaction = formattedResponse;
        this.loadingService.hideSection();
      },
      error => {
        console.error('Error fetching market reaction:', error);
        this.loadingService.hideSection();
      }
    );
  }

  generateFinanceSchemaMarkup(items: NewsEvent[]): void {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": items.splice(0, 20).map((item, index) => ({
        "@type": "CreativeWork",
        "headline": item.title,
        "url": item.link,
        "datePublished": item.time,
        "description": item.stock ? `Stock: ${item.stock}` : undefined,
        "publisher": item.resource ? {
          "@type": "Organization",
          "name": item.resource
        } : undefined
      }))
    };

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    this.renderer.appendChild(this.document.head, script);
  }
}
