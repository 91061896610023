<section class="section banner" id="banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>Cizeex.</h2>
                    <h3>Insight. Growth. Together.</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner icon-order">
                <button class="main-btn main-btn-icon" title="Cizeex+" routerLink="/registration"
                    [queryParams]="{ role: Roles.Cizeex }"><i>Cizeex+</i>
                </button>
                <button class="main-btn main-btn-icon" title="Market Insight" routerLink="/market">
                    <i class="fas fa-chart-line"></i>
                </button>
                <button class="main-btn main-btn-icon" title="CodeCollaborate" routerLink="/code-collaborate">
                    <i class="fas fa-laptop-code"></i>
                </button>
                <button class="main-btn main-btn-icon" title="Articles" routerLink="/articles">
                    <i class="fas fa-book-reader"></i>
                </button>
            </div>
        </div>
    </div>
</section>