import { Component } from '@angular/core';

@Component({
  selector: 'app-article-editor-wrapper',
  templateUrl: './article-editor-wrapper.component.html',
  styleUrl: './article-editor-wrapper.component.scss'
})
export class ArticleEditorWrapperComponent {
  isFullSize: boolean = false;

  handleFullSizeChange(isFullSize: boolean) {
    this.isFullSize = isFullSize;
  }
}
