import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private analyticsService: AnalyticsService,
    private metaService: MetaService) {};

  ngOnInit(): void {
    this.analyticsService.sendAnalyticPageView("/home", "home");
    this.metaService.setMetaTags({
      title: 'Cizeex - Insight. Growth. Together.',
      description: 'Dive into our Learning Hub with AI-powered educational resources, and explore top job opportunities with our curated Job Listings.',
      keywords: "Market Insights, Economic Calendar AI, Rejected, Learning Hub, Job Listings, articles"
    });
  }
}
