import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BannerService } from 'src/app/services/banner/banner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/models/article.model';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-article-editor',
  templateUrl: './article-editor.component.html',
  styleUrls: ['./article-editor.component.scss']
})
export class ArticleEditorComponent implements OnInit {
  currentView: 'editor' | 'preview' | 'both' = 'both';
  private _notFullSizeScreen: boolean = true;
  articleId: number | null = null;
  article: Article | null = null;
  currentLock: boolean = false;
  username: string | undefined;
  private _auth: AuthService;
  showLock: boolean = false;
  currentFloating = 'left'
  currentTheme = 'dark';
  isPreviewMode = true;
  isEditMode = false;
  @Output() fullSizeScreenChange = new EventEmitter<boolean>();

  constructor(
    private articleService: ArticleService,
    private loadingService: LoadingService,
    private bannerService: BannerService,
    private authService: AuthService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this._auth = authService;
  }

  get auth(): AuthService {
    return this._auth;
  }

  get fullSizeScreen(): boolean {
    return this._notFullSizeScreen;
  }

  toggleFullSize() {
    this.fullSizeScreenChange.emit(this._notFullSizeScreen);
    this._notFullSizeScreen = !this._notFullSizeScreen;
  }

  ngOnInit(): void {
    this.username = this._auth.currentUserInfoValue?.username;
    this.route.paramMap.subscribe(params => {
      this.articleId = +params.get('id');
      this.checkMode();
    });
  }

  increaseViewer() {
    if (this.username !== this.article.authorName) {
      const viewedArticles = localStorage.getItem('viewedArticles');
      const articleViewed = viewedArticles?.includes(`${this.articleId}`);
      if (!articleViewed) {
        this.articleService.incrementViews(this.articleId).subscribe(
          (response) => this.storeViewedArticle()
        );
      }
    }
  }

  storeViewedArticle(): void {
    const viewedArticles = localStorage.getItem('viewedArticles');
    let viewedArticlesArray: any[];

    if (viewedArticles) {
      viewedArticlesArray = JSON.parse(viewedArticles);
    } else {
      viewedArticlesArray = [];
    }

    viewedArticlesArray.push(this.articleId);
    localStorage.setItem('viewedArticles', JSON.stringify(viewedArticlesArray));
  }

  checkMode() {
    if (this.articleId) {
      this.fetchArticle(this.articleId);
    } else {
      this.isEditMode = true;
      this.checkPreviewMode();
      this.article = {
        id: 0,
        title: '',
        summary: '',
        content: '',
        authorId: 0,
        authorName: '',
        createdAt: '',
        publishedAt: '',
        updatedAt: '',
        likes: 0,
        views: 0,
        commentsCount: 0,
        coverImageUrl: '',
        tags: [],
        socialShareUrl: '',
        isHidden: false
      };
    }
  }

  toggleView() {
    if (this.currentView === 'editor') {
      this.currentView = 'both';
    } else if (this.currentView === 'both') {
      this.currentView = 'preview';
    } else {
      this.currentView = 'editor';
    }
  }

  toggleTheme() {
    this.currentTheme = this.currentTheme === 'dark' ? 'light' : 'dark';
  }

  toggleFloating() {
    this.currentFloating = this.currentFloating === 'left' ? 'right' : 'left';
  }

  toggleLock() {
    this.currentLock = !this.currentLock;
    if (this.articleId && this.article.isHidden == false) {
      this.hideArticle(this.articleId)
    }
    else if (this.articleId && this.article.isHidden == true) {
      this.unhideArticle(this.articleId)
    }
    else {
      this.bannerService.showBanner("Failed to hide article! Please try again.", "warning", true);
    }
  }

  fetchArticle(articleId: number) {
    this.loadingService.showSection();
    this.articleService.getArticleById(articleId).subscribe(
      article => {
        this.article = article;
        this.currentLock = article.isHidden;
        this.checkPreviewMode();
        this.loadingService.hideSection();
        this.increaseViewer();
        this.metaService.setMetaTags({
          title: article.title,
          description: article.content ? article.content.substring(0, 60) : '',
          keywords: article.authorName
        });
      },
      (error) => {
        this.bannerService.showBanner("Failed to hide article! Please try again.", "error", true);
        this.loadingService.hideSection();
      }
    );
  }
  checkPreviewMode() {
    if (this.username && this.article && (this.article.authorName !== this.username)) {
      this.isEditMode = false;
      this.isPreviewMode = true;
      this.currentView = 'preview';
    }
    else if (this.username && this.isEditMode) {
      this.isPreviewMode = false;
      this.currentView = 'both';
    }
    else if (!this.username) {
      this.isEditMode = false;
      this.isPreviewMode = true;
      this.currentView = 'preview';
    }
    else {
      this.isEditMode = true;
      this.isPreviewMode = false;
      this.currentView = 'both';
    }
    if (this.username && this.article && (this.article.authorName === this.username)) {
      this.showLock = true;
    }
  }

  saveArticle() {
    if (!this.article.title){
      this.bannerService.showBanner("Missing Title!", "warning", true);
      return;
    }
    if (this.isEditMode && this.username && this.article.authorName == this.username) {
      this.articleService.updateArticle(this.articleId!, this.article).subscribe(
        () => {
          this.bannerService.showBanner("Successfully updated article!", "success", true);
        },
        (error) => {
          this.bannerService.showBanner("Failed to save article! Please try again.", "error", true);
          this.loadingService.hideSection();
        }
      );
    } else if (this.isEditMode && this.username) {
      this.articleService.createArticle(this.article).subscribe(
        (response) => {
          this.bannerService.showBanner("Successfully saved article!", "success", true);
          this.router.navigate(['/articles']);
        },
        (error) => {
          this.bannerService.showBanner("Failed to save article! Please try again.", "error", true);
          this.loadingService.hideSection();
        }
      );
    }
  }

  autoSave(content: string) {
    if (this.articleId) {
      this.articleService.autoSave(this.articleId, content);
    }
  }

  hideArticle(articleId: number): void {
    this.loadingService.showSection();
    this.articleService.hideArticle(articleId).subscribe(
      (response) => {
        this.bannerService.showBanner("Successfully hide article!", "success", true);
        this.loadingService.hideSection()
      },
      (error) => {
        this.bannerService.showBanner("Failed to hide article! Please try again.", "error", true);
        this.loadingService.hideSection();
      }
    );
  }

  unhideArticle(articleId: number): void {
    this.loadingService.showSection();
    this.articleService.unhideArticle(articleId).subscribe(
      (response) => {
        this.bannerService.showBanner("Successfully unhide article!", "success", true);
        this.loadingService.hideSection();
      },
      (error) => {
        this.bannerService.showBanner("Failed to unhide article! Please try again.", "error", true);
        this.loadingService.hideSection();
      }
    );
  }

  likeArticle(articleId: number): void {
    if (this.username !== this.article.authorName) {
      const likedArticles = localStorage.getItem('likedArticles');
      const articleLiked = likedArticles?.includes(`${this.articleId}`);
      if (!articleLiked) {
        this.articleService.likeArticle(articleId).subscribe(
          (response) => {
            this.storeLikedArticle()
            console.log("Successfully liked article!");
          },
          (error) => {
            this.bannerService.showBanner("Failed to like article! Please try again.", "error", true);
          }
        );
      }
    }
  }

  storeLikedArticle(): void {
    const likedArticles = localStorage.getItem('likedArticles');
    let likedArticlesArray: any[];
    if (likedArticles) {
      likedArticlesArray = JSON.parse(likedArticles);
    } else {
      likedArticlesArray = [];
    }
    likedArticlesArray.push(this.articleId);
    localStorage.setItem('likedArticles', JSON.stringify(likedArticlesArray));
  }

  deleteArticle(articleId: number): void {
    this.loadingService.showSection();
    if (confirm('Are you sure you want to delete this article?')) {
      this.articleService.deleteArticle(articleId).subscribe(
        (response) => {
          this.bannerService.showBanner("Successfully delete article!", "success", true);
          this.loadingService.hideSection();
          this.router.navigate(['/articles']);
        },
        (error) => {
          this.bannerService.showBanner("Failed to delete article! Please try again.", "error", true);
          this.loadingService.hideSection();
        }
      );
    }
  }
}


