<div *ngIf="loading$ | async" data-aos="fade-up">
    <div class="loader-container">
        <div class="bouncing-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</div>

<div *ngIf="loadingSection$ | async" data-aos="fade-up">
    <div class="loader-section-container">
        <div class="bouncing-dots">
            <div class="dot-section"></div>
            <div class="dot-section"></div>
            <div class="dot-section"></div>
            <div class="dot-section"></div>
            <div class="dot-section"></div>
        </div>
    </div>
</div>

<div *ngIf="loadingOverlay$ | async">
    <div class="loader-overlay-container">
        <div class="bouncing-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</div>