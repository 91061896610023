// prompt-ui.component.ts
import { Component, Input, AfterViewChecked, ElementRef, ViewChild, OnChanges, SimpleChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BannerService } from 'src/app/services/banner/banner.service';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';
import { PromptService } from 'src/app/services/prompt/prompt.service';

@Component({
  selector: 'app-prompt-ui',
  templateUrl: './prompt-ui.component.html',
  styleUrls: ['./prompt-ui.component.scss'],
})
export class PromptUiComponent implements OnChanges, AfterViewChecked {
  @Input() isDisabled: boolean = true;
  @Input() aiReaction: string = '';
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('messageText') messageText: ElementRef;
  selectedModelId: string = 'llama-3.2-90b-vision-preview'; // Default model ID
  selectedModelName: string = 'Llama 3.2 Vision (90B)'; // Default model name
  isTyping = false;

  aiModels = [
    { id: "llama3-70b-8192", name: "Llama Ultra" },
    { id: "qwen-2.5-32b", name: "Qwen Master" },
    { id: "whisper-large-v3-turbo", name: "Whisper Turbo" },
    { id: "llama-3.2-90b-vision-preview", name: "Llama Vision Pro" },
    { id: "distil-whisper-large-v3-en", name: "Whisper Lite" },
    { id: "deepseek-r1-distill-llama-70b", name: "DeepSeek Llama" }
  ];

  onModelChange() {
    const selectedModel = this.aiModels.find(model => model.id === this.selectedModelId);
    this.selectedModelName = selectedModel ? selectedModel.name : null;
  }
  messages: { text: string }[] = [];
  userInput: string = '';

  constructor(
    private marketReactionService: MarketReactionService,
    private analyticsService: AnalyticsService,
    private promptService: PromptService,
    private bannerService: BannerService,
    private authService: AuthService,
    private sanitizer: DomSanitizer) {
    this.isDisabled = !authService.isAuthorized(authService.currentUserInfoValue, [Roles.Cizeex], [Plans.Starter]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aiReaction && changes.aiReaction.currentValue && changes.aiReaction.currentValue !== '') {
      // Handle changes to aiReaction and isDisabled if needed
      this.messages.push({ text: this.aiReaction });
    }
  }

  ngAfterViewChecked() {
    if (this.messages.length > 1)
      this.scrollToBottom();
  }

  sendMessage() {
    this.analyticsService.sendAnalyticPageView("/prompt", "prompting_ai");
    const trimmedUserInfo = this.userInput?.trim();

    if (trimmedUserInfo) {
      const lastMessage = this.messages.length > 0 ? this.plainText(this.messages[this.messages.length - 1].text) : null;
      const modelId = this.selectedModelId;

      this.promptService.sendMessage(trimmedUserInfo, lastMessage, modelId).subscribe(
        async (data) => {
          this.isTyping = true; // Disable sending new messages

          const responseText = data.response.toString();
          const formattedResponse = await this.marketReactionService.convertAiToHtml(responseText);

          // Initialize the message with an empty string to animate typing
          this.messages.push({ text: '' });

          // Typing effect
          this.simulateTyping(formattedResponse, this.messages.length - 1);
        },
        (error) => this.bannerService.showBanner("error.message", "error", true)
      );

      this.messages.push({ text: this.userInput.trim() });
      this.userInput = '';
    }
  }

  simulateTyping(fullText: string, index: number) {
    let i = 0;
    const interval = setInterval(() => {
      if (i < fullText.length) {
        this.messages[index].text += fullText[i];
        i++;
      } else {
        clearInterval(interval);
        this.isTyping = false; // Re-enable sending messages
      }
    }, 10); // Adjust speed of typing effect
  }

  plainText(htmlValue: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, htmlValue)
      .replace(/<[^>]*>/g, "");
  }

  likeMessage(message: { text: string }) {
    console.log('Liked message:', message.text);
  }

  copyMessage() {
    navigator.clipboard.writeText(this.messageText.nativeElement.textContent +
      "\nCheckout https://www.cizeex.com for more info.").then(() => {
      }).catch((err) => {
        console.error('Error copying text: ', err);
      });
  }

  private scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Could not scroll to bottom:', err);
    }
  }
}
