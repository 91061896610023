<div class="tabs-container">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="horizontal" class="nav-tabs tabs">
    <li *ngFor="let tab of tabsInfo; let i = index" [ngbNavItem]="i">
      <ng-container *ngIf="tab.Path && tab.Path.trim() !== ''; else noPath">
        <a ngbNavLink [routerLink]="tab.Path" routerLinkActive="active" (click)="onTabClick(i)"
          [class.active]="activeTab === tab.Path">{{ tab.Title }}</a>
      </ng-container>
      <ng-template #noPath>
        <a ngbNavLink (click)="onTabClick(i)">{{ tab.Title }}</a>
      </ng-template>
    </li>
  </ul>
  <br>
  <div *ngFor="let tab of tabsInfo; let i = index" [ngClass]="{'d-none': active !== i}">
    <h4 class="title-tab-content" *ngIf="tab.SubTitle && tab.Tab">
      {{ tab.SubTitle }} |
      <span class="tab-content">{{ tab.Tab }}</span>
    </h4>
    <h5 class="tab-time" *ngIf="tab.Date">{{ tab.Date }}</h5>
    <p *ngFor="let item of tab.Description; let i = index" class="points-description" [innerHTML]="item | translate">
    </p>
  </div>
  <ng-container [ngComponentOutlet]="contentComponent"></ng-container>
</div>