import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private $gaService: GoogleAnalyticsService
  ) { }

  sendAnalyticEvent(eventAction: string, eventCategory: string, eventLabel: string, eventData?: any) {
    try {
      this.$gaService.event(eventAction, eventCategory, eventLabel, eventData);
    } catch (error) {
      console.error('Google Analytics event tracking error:', error);
    }
  }

  sendAnalyticPageView(path: string, title: string) {
    try {
      this.$gaService.pageView(path, title)
    } catch (error) {
      console.error('Google Analytics page view error:', error);
    }
  }
}
