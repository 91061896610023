<section class="section" id="market">
    <div [ngClass]="isFullSize ? 'margin-right-left-100 size-transition full' : 'container size-transition'">
        <div class="section-box">
            <div class="cizeex-plus-title" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">02.</span>
                    {{ "Article.Title" | translate }}
                </h3>
            </div>
            <div>
                <app-article-editor (fullSizeScreenChange)="handleFullSizeChange($event)"></app-article-editor>
            </div>
        </div>
    </div>
</section>