<section class="section code-collaborate" id='code-collaborate' title="CodeCollaborate">
    <div class="container">
        <div class="section-box">
            <div class="code-collaborate-title" data-aos="fade-up">
                <h3 class="section-title" alt="CodeCollaborate">
                    <span class='n-section-title'>02.</span> {{"CodeCollaborate.Title" | translate}}
                </h3>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" #imgContainer class='code-collaborate-img-banner'>
                <img class='code-collaborate-img rounded' [src]='"CodeCollaborate.Img" | translate' alt="CodeCollaborate">
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-description">
                <p class="mt-4" [innerHTML]='"CodeCollaborate.Description" | translate'></p>
            </div>
            <div>
                <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">Why CodeCollaborate?</h5>
                <p data-aos="fade-up" data-aos-duration="1000"
                    *ngFor="let item of 'CodeCollaborate.Description.Why'| translate; let i = index"
                    class="points-description" [innerHTML]="item | translate"></p>
            </div>
            <div>
                <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">For Coders:</h5>
                <p data-aos="fade-up" data-aos-duration="1000"
                    *ngFor="let item of 'CodeCollaborate.Description.ForCoders'| translate; let i = index"
                    class="points-description" [innerHTML]="item | translate"></p>
            </div>
            <div>
                <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">For Collaborators:</h5>
                <p data-aos="fade-up" data-aos-duration="1000"
                    *ngFor="let item of 'CodeCollaborate.Description.ForCollaborators'| translate; let i = index"
                    class="points-description" [innerHTML]="item | translate"></p>
            </div>
            <div>
                <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">Key Features:</h5>
                <p data-aos="fade-up" data-aos-duration="1000"
                    *ngFor="let item of 'CodeCollaborate.Description.Points'| translate; let i = index"
                    class="points-description" [innerHTML]="item | translate"></p>
            </div>
            <div>
                <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">Join Me Today!</h5>
                <p data-aos="fade-up" data-aos-duration="1000" class="mt-4"
                    [innerHTML]='"CodeCollaborate.Description.JoinMe" | translate'></p>
            </div>
            <div class="button-container" data-aos="fade-up" data-aos-duration="1000">
                <button routerLink="/registration" [queryParams]="{ role: Roles.Coder }">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Become a <span class="underline">Coder</span>
                    </a>
                </button>
                <button routerLink="/registration" [queryParams]="{ role: Roles.Collaborator }">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Become a <span class="underline">Collaborator</span>
                    </a>
                </button>
            </div>
        </div>
    </div>
</section>