// meta.service.ts
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private meta: Meta, private title: Title) {}

  // Method to set the title
  setTitle(title: string): void {
    this.title.setTitle(title);
  }

  // Method to set the meta description
  setDescription(description: string): void {
    this.meta.updateTag({ name: 'description', content: description });
  }

  // Method to set keywords
  setKeywords(keywords: string): void {
    this.meta.updateTag({ name: 'keywords', content: keywords });
  }

  // Method to set multiple tags at once
  setMetaTags({ title, description, keywords }: { title: string; description: string; keywords?: string }): void {
    if (title) this.setTitle(title);
    if (description) this.setDescription(description);
    if (keywords) this.setKeywords(keywords);
  }
}
