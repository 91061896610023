import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GithubAuthService {
  private apiUrl = environment.authApiUrl;
  private orgName = environment.ghOrgName;

  constructor(private http: HttpClient) { }

  // Get GitHub Token using authorization code
  getGitHubToken(code: string): Observable<any> {
    const url = `${this.apiUrl}auth/github/token`;
    return this.http.post(url, { code }).pipe(
      catchError(this.handleError('getGitHubToken'))
    );
  }

  // Check if the user is a member of the GitHub organization
  isUserEnrolledInOrg(githubToken: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Authorization': `token ${githubToken}`
    });

    return this.getGitHubUsername(githubToken).pipe(
      switchMap(username => {
        const url = `https://api.github.com/orgs/${this.orgName}/memberships/${username}`;
        return this.http.get<any>(url, { headers }).pipe(
          map(response => response.state === 'active'), // Active member
          catchError(() => of(false))  // Return false on any error
        );
      })
    );
  }

  // Enroll user in GitHub Organization
  enrollInGitHubOrg(): Observable<any> {
    const githubToken = localStorage.getItem('github_token');
    if (!githubToken) {
      return throwError(() => new Error('GitHub token missing. Link your account first.'));
    }

    return this.getGitHubUsername(githubToken).pipe(
      switchMap(username => {
        const url = `${this.apiUrl}auth/github/enroll`;
        return this.http.post(url, { username }).pipe(
          catchError(this.handleError('enrollInGitHubOrg'))
        );
      })
    );
  }

  // Get the list of user repositories
  getUserRepos(): Observable<any> {
    const githubToken = localStorage.getItem('github_token');
    if (!githubToken) {
      return throwError(() => new Error('GitHub token missing. Link your account first.'));
    }

    const headers = new HttpHeaders({
      'Authorization': `token ${githubToken}`
    });

    return this.http.get(`https://api.github.com/user/repos`, { headers }).pipe(
      catchError(this.handleError('getUserRepos'))
    );
  }

  // Get GitHub Username
  private getGitHubUsername(githubToken: string): Observable<string> {
    const url = 'https://api.github.com/user';
    const headers = new HttpHeaders({
      'Authorization': `token ${githubToken}`
    });

    return this.http.get<any>(url, { headers }).pipe(
      map(response => response.login),  // Username is in 'login'
      catchError(this.handleError('getGitHubUsername'))
    );
  }

  // Generic error handler
  private handleError(operation: string) {
    return (error: HttpErrorResponse): Observable<never> => {
      console.error(`${operation} failed: ${error.message}`);
      return throwError(() => new Error('Something went wrong. Please try again later.'));
    };
  }
}

