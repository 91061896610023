import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements AfterViewInit {
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}