<div>
    <div class="icon-order">
        <button class="main-btn main-btn-icon" routerLink="/articles" title="Go Back To Articles">
            <i class="fas fa-arrow-left"></i>
        </button>
        <button *ngIf="!isPreviewMode" class="main-btn main-btn-icon" (click)="toggleView()"
            title="Change Current View">
            <i [ngClass]="{
                        'fa fa-eye': currentView === 'preview',
                        'fa fa-eye-slash': currentView === 'editor',
                        'fas fa-compress-arrows-alt': currentView === 'both'
                        }"></i>
        </button>
        <button *ngIf="currentView !== 'editor'" class="main-btn main-btn-icon" (click)="toggleTheme()"
            title="Change Current Theme">
            <i [ngClass]="currentTheme !== 'dark' ? 'fas fa-sun' : 'fas fa-moon'"></i>
        </button>
        <button class="main-btn main-btn-icon" (click)="toggleFloating()" title="Change Text align">
            <i [ngClass]="currentFloating === 'left' ? 'fas fa-align-left' : 'fas fa-align-right'"></i>
        </button>
        <button disabled class="main-btn main-btn-icon" title="Create Daily AI Articles with Cizeex+">
            <i class="fas fa-robot"></i>
        </button>
        <button class="main-btn main-btn-icon" (click)="toggleFullSize();" title="Full Preview Screen">
            <i [ngClass]="fullSizeScreen === false ? 'fas fa-compress-alt' : 'fas fa-expand-alt'"></i>
        </button>
        <button *ngIf="showLock && username" class="main-btn main-btn-icon" (click)="toggleLock();"
            title="Hide/Unhide Article">
            <i [ngClass]="currentLock === true ? 'fas fa-lock-open' : 'fas fa-lock'"></i>
        </button>
        <button *ngIf="!isPreviewMode && username" class="main-btn main-btn-icon" (click)="saveArticle()"
            title="Save Article">
            <i class="far fa-save"></i>
        </button>
        <button *ngIf="!isPreviewMode && username" class="main-btn main-btn-icon"
            (click)="deleteArticle(article.id)" title="Delete Article">
            <i class="fas fa-trash-alt"></i>
        </button>
        <button *ngIf="isPreviewMode && !username" class="main-btn main-btn-icon"
            (click)="likeArticle(article.id)" title="Like Article">
            <i class="fas fa-thumbs-up"></i>
        </button>
    </div>
    <app-loading *ngIf="!article"></app-loading>
    <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" *ngIf="article" class="editor-preview-container-title">
        <div class="editor" *ngIf="currentView === 'editor' || currentView === 'both'">
            <input type="text" class="input-title" [(ngModel)]="article.title" placeholder="Enter title" />
        </div>
    </div>
    <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" *ngIf="article"
        [ngClass]="currentView === 'preview' ? 'editor-preview-container' : 'editor-preview-container generic-full-container'">
        <!-- Editor Section -->
        <div class="editor" *ngIf="currentView === 'editor' || currentView === 'both'">
            <textarea [(ngModel)]="article.content" placeholder="Enter content"
                (keyup)="autoSave(article.content)"></textarea>
        </div>

        <!-- Preview Section -->
        <div [ngClass]="currentTheme === 'dark' ? 'preview' : 'preview preview-light'"
            *ngIf="currentView === 'preview' || currentView === 'both'">
            <h4 *ngIf="isEditMode">Preview:</h4>
            <h2 class="preview-title">{{article.title}}</h2>
            <div class="custom-style">
                <markdown [ngClass]="currentFloating === 'right' ? 'variable-binding rtl' : 'variable-binding ltr'"
                    [data]="article.content"></markdown>
            </div>
        </div>
    </div>
</div>