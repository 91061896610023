import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { NewsComponent } from './news/news.component';
import { DiscordHelpComponent } from '../general/discord-help/discord-help.component';
import { ChartsComponent } from './charts/charts.component';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrl: './market.component.scss'
})
export class MarketComponent implements AfterViewInit, OnInit {
  activeTab: string = 'news'; // Default tab
  contentComponent: any = NewsComponent;

  tabs = [
    { title: "News", path: "news", component: NewsComponent },
    { title: "Economic Calendar", path: "economic-calendar", component: EconomicCalendarComponent },
    { title: "Charts", path: "charts", component: ChartsComponent },
    { title: "Join Discord", path: "join-discord", component: DiscordHelpComponent },
    { title: "Securities Disclaimer", path: "securities-disclaimer", component: null }
  ];

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // Listen to route changes and update activeTab
    this.route.firstChild?.url.subscribe(urlSegments => {
      const currentPath = urlSegments ? urlSegments[0].path : 'news';
      this.activeTab = currentPath;
      const activeTabConfig = this.tabs.find(tab => tab.path === currentPath);
      this.contentComponent = activeTabConfig ? activeTabConfig.component : null;
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  onTabSelected(index: number) {
    const selectedTab = this.tabs[index];

    if (selectedTab.path) {
      this.router.navigate(['/market', selectedTab.path]);
    }
    this.contentComponent = selectedTab.component || null;
  }
}
