<!-- prompt-ui.component.html -->
<div class="prompt-ui-container">
    <div class="messages-container" #messagesContainer>
        <div class="message" *ngFor="let message of messages">
            <div class="message-text" [innerHTML]="message.text" #messageText></div>
            <div class="actions">
                <!-- <span><i (click)="likeMessage(message)" class="fa fa-rocket" aria-hidden="true"></i></span> -->
                <span><i (click)="copyMessage()" class="fas fa-copy" aria-hidden="true"></i></span>
            </div>
        </div>
    </div>
    <div *ngIf="isDisabled" class="disabled-warning">
        <span class="underline">Upgrade to Cizeex+ to unlock unlimited prompts!</span>
    </div>
    <div class="input-container" [ngClass]="{'disabled': isDisabled}">
        <textarea [(ngModel)]="userInput" [disabled]="isDisabled" placeholder="Ask Cizeex here..."></textarea>
        <div class="send-icon-container" (click)="sendMessage();">
            <span>
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </span>
        </div>
    </div>
    <div class="ai-warning">
        Note: This AI can make mistakes. Please verify the information.
    </div>
</div>