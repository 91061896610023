import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ArticleService } from 'src/app/services/article/article.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Article } from 'src/app/models/article.model';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MetaService } from 'src/app/services/meta/meta.service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('600ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ArticleListComponent implements OnInit {
  private _auth: AuthService;
  articles: Article[] = [];
  hasData: boolean = false;
  yourFiles = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private articleService: ArticleService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private metaService: MetaService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this._auth = authService;
  }
  get auth(): AuthService {
    return this._auth;
  }
  ngOnInit(): void {
    this.fetchNew();
    this.metaService.setMetaTags({
      title: 'ArtIcles',
      description: 'Each user has access to personalized content, a seamless AI-powered prompt system for creating and engaging with articles',
      keywords: 'Posts, wikipedia, wiki, articles, markdown'
    });
  }

  fetchNew(userId?: number) {
    this.hasData = false;
    this.loadingService.show();
    this.articleService.getArticles(userId && this.yourFiles ? userId : null)
      .subscribe(
        data => {
          this.articles = data;
          this.hasData = true;
          this.loadingService.hide();
          this.generateSchemaMetaMarkup(this.articles);
        },
        error => {
          console.error('Error fetching news data:', error);
          this.loadingService.hide();
        }
      );
  }

  onRowClicked(event: any) {
    this.router.navigate([`/articles/${event.id}`]);
  }

  goToEditor(): void {
    this.router.navigate(['/articles/new']);
  }

  generateSchemaMetaMarkup(articles: Article[]): void {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": articles.slice(0, 10).map((article, index) => ({
          "@type": "Article",
          "headline": article.title,
          "url": `https://cizeex.com/articles/${article.id}-${article.title}`,
          "datePublished": article.publishedAt,
          "description": article.summary,
          "tags": article.tags?.join(', ') || "No tags",
          "author": {
            "@type": "Person",
            "name": article.authorName
          }
        }))
      }
    };

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    this.renderer.appendChild(this.document.head, script);
  }
}
