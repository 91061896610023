<section class="section" id="code-collaborate-dashboard">
    <div class="container">
        <div class="section-box">
            <div class="cizeex-plus-title">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">02.</span>
                    {{"CodeCollaborate.Title" | translate}} Dashboard
                </h3>
            </div>
            <div class="generic-full-container" data-aos="flip-up" data-aos-duration="1000">
                <div class="github-setup">
                    <!-- If GitHub is not linked -->
                    <div *ngIf="!isGitHubLinked">
                        <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">
                            <span class="code-font n-section-title">01.</span>
                            {{"CodeCollaborate.Guide.Subject" | translate}}
                        </h5>
                        <p data-aos="fade-up" data-aos-duration="1000"
                            *ngFor="let item of 'CodeCollaborate.Guide.Steps' | translate; let i = index"
                            class="points-description" [innerHTML]="item | translate"></p>
                        <button (click)="linkGitHubAccount()">
                            <a class="btn btn-1" target="_blank">
                                <svg>
                                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                </svg>
                                {{"CodeCollaborate.Guide.Link.Button" | translate}}
                            </a>
                        </button>
                        <p *ngIf="isGitHubTokenMissing" class="error-message">
                            {{"CodeCollaborate.Guide.MissingToken" | translate}}
                        </p>
                    </div>

                    <!-- If GitHub is linked -->
                    <div *ngIf="isGitHubLinked">
                        <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">
                            <span class="code-font n-section-title">01.</span>
                            {{"CodeCollaborate.Guide.Successful" | translate}}
                        </h5>
                        <div class="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Repository Name</th>
                                        <th>Created At</th>
                                        <th class="hide-on-mobile">Main Language</th>
                                        <th class="hide-on-mobile">Stars</th>
                                        <th class="hide-on-mobile">Forks</th>
                                        <th>GitHub Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let repo of repos">
                                        <td><span class="underline">
                                                <a href="{{ repo.html_url }}" target="_blank"
                                                    style="color: inherit; text-decoration: none;">{{
                                                    repo.name }}
                                                </a>
                                            </span></td>
                                        <td>{{ repo.created_at | date: 'yyyy-MM-dd' }}</td>
                                        <td class="hide-on-mobile">{{ repo.language || 'N/A' }}</td>
                                        <td class="hide-on-mobile">{{ repo.stargazers_count || 'N/A' }}</td>
                                        <td class="hide-on-mobile">{{ repo.forks_count || 'N/A' }}</td>
                                        <td>
                                            <a href="{{ repo.html_url }}" target="_blank" style="color: inherit">
                                                <i class="ml-4 fab fa-github"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div *ngIf="!repos || repos.length === 0">
                            <p>{{"CodeCollaborate.Guide.NoRepos" | translate}}</p>
                        </div>
                    </div>
                </div>

                <!-- Loader component -->
                <app-loading></app-loading>
            </div>
            <div class="generic-full-container" data-aos="fade-up" data-aos-duration="1000">
                <div class="github-setup">
                    <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">
                        <span class="code-font n-section-title">02.</span>
                        {{"CodeCollaborate.Guide.IdeSubject" | translate}}
                    </h5>
                    <!-- Iterate over the IDE array -->
                    <div *ngFor="let guide of 'CodeCollaborate.Guide.IDE' | translate; let i = index">
                        <h6 (click)="guide.isOpen = !guide.isOpen" class="clickable-title">
                            {{ guide.Platform }}
                            <span class="toggle-icon" [ngClass]="{'expanded': guide.isOpen}">&#9660;</span>
                            <!-- Down arrow icon -->
                        </h6>
                        <!-- Display the steps for the current guide if expanded -->
                        <div *ngIf="guide.isOpen" class="guide-content">
                            <img *ngIf="guide.isOpen" [src]="guide.Img" alt="{{ guide.Platform }}" class="guide-image"
                                height="100px" width="100px">
                            <p *ngFor="let step of guide.Steps" class="points-description"
                                [innerHTML]="step | translate"></p>
                            <button>
                                <a class="btn btn-1" [href]="guide.Link.Href" target="_blank">
                                    <svg>
                                        <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                    </svg>
                                    {{ guide.Link.Button | translate }}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="generic-full-container" data-aos="fade-up" data-aos-duration="1000">
                <div class="github-setup">
                    <h5 data-aos="fade-up" data-aos-duration="1000" class="code-collaborate-time">
                        <span class="code-font n-section-title">03.</span>
                        {{"CodeCollaborate.Guide.RepositorySubject" | translate}}
                    </h5>
                    <!-- Iterate over the IDE array -->
                    <div *ngFor="let guide of 'CodeCollaborate.Guide.Repository' | translate; let i = index">
                        <h6 (click)="guide.isOpen = !guide.isOpen" class="clickable-title">
                            {{ guide.Title }}
                            <span class="toggle-icon" [ngClass]="{'expanded': guide.isOpen}">&#9660;</span>
                            <!-- Down arrow icon -->
                        </h6>
                        <!-- Display the steps for the current guide if expanded -->
                        <div *ngIf="guide.isOpen" class="guide-content">
                            <p *ngFor="let step of guide.Steps" class="points-description"
                                [innerHTML]="step | translate"></p>
                            <button>
                                <a class="btn btn-1" [href]="guide.Link.Href" target="_blank">
                                    <svg>
                                        <rect x="0" y="0" fill="none" width="100%" height="100%" />
                                    </svg>
                                    {{ guide.Link.Button | translate }}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <app-discord-help sectionNumber="04."></app-discord-help>
        </div>
    </div>
</section>