<!-- prompt-ui.component.html -->
<div class="prompt-ui-container">
    <!-- AI Model Selection -->
    <div class="model-selection">
        <label for="aiModel">Choose an AI Model:</label>
        <select id="aiModel" [(ngModel)]="selectedModelId" (change)="onModelChange()">
            <option *ngFor="let model of aiModels" [value]="model.id">{{ model.name }}</option>
        </select>
    </div>

    <div class="messages-container" #messagesContainer>
        <div class="message" *ngFor="let message of messages">
            <div class="message-text" [innerHTML]="message.text"></div>
            <div class="actions">
                <span><i (click)="copyMessage()" class="fas fa-copy"></i></span>
            </div>
        </div>
    </div>

    <div *ngIf="isDisabled" class="disabled-warning">
        <span class="underline">Upgrade to Cizeex+ to unlock unlimited prompts!</span>
    </div>
    <div class="input-container" [ngClass]="{'disabled': isDisabled || isTyping}">
        <textarea [(ngModel)]="userInput" [disabled]="isDisabled"
            placeholder="Ask {{ selectedModelName }} here..."></textarea>
        <div class="send-icon-container" (click)="!isTyping && sendMessage();">
            <span>
                <i class="fa fa-paper-plane"></i>
            </span>
        </div>
    </div>

    <div class="ai-warning">
        Note: This AI can make mistakes. Please verify the information.
    </div>
</div>