import { Component } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-cc-ad',
  templateUrl: './cc-ad.component.html',
  styleUrl: './cc-ad.component.scss'
})
export class CcAdComponent {
  Roles = Roles;
  isModalVisible = true; // Modal is visible by default on page load
  private _auth: AuthService;

  constructor(
    public analyticsService: AnalyticsService,
    private authService: AuthService
  ) { 
    this._auth = authService;
  }

  get auth(): AuthService {
    return this._auth;
  }
  
  closeModal() {
    this.isModalVisible = false;
  }
}
