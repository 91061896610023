import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrl: './user-header.component.scss'
})
export class UserHeaderComponent {
  private _auth: AuthService;

  constructor(private authService: AuthService) {
    this._auth = authService;
  }

  get auth(): AuthService {
    return this._auth;
  }

  get role(): string {
    return this._auth.currentUserInfoValue.role;
  }

  logout(): void {
    this.auth.logout();
  }
}
