// loading.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private loadingSectionSubject = new BehaviorSubject<boolean>(false);
  loadingSection$ = this.loadingSectionSubject.asObservable();

  private loadingOverlay = new BehaviorSubject<boolean>(false);
  loadingOverlay$ = this.loadingOverlay.asObservable();

  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
  }

  showSection() {
    this.loadingSectionSubject.next(true);
  }

  hideSection() {
    this.loadingSectionSubject.next(false);
  }

  showOverlay() {
    this.loadingOverlay.next(true);
  }

  hideOverlay() {
    this.loadingOverlay.next(false);
  }
}